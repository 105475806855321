import React, { useState, useEffect } from "react";
import "./urlaub.css";
import supabase from "../../../LogIn/supabaseClient";
import { DatePicker } from 'react-rainbow-components';

const Urlaub = () => {
  const [users, setUsers] = useState([]);
  const [range, setRange] = useState(undefined);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let { data, error } = await supabase.from("bees_urlaub").select("*");
    if (data) setUsers(data);
    if (error) console.error(error);
  };

  const handleAddUser = async () => {
    const isoFormatAnfang = convertToISOFormat(range[0]);
    const isoFormatEnde = convertToISOFormat(range[1]);
    const { error } = await supabase
      .from("bees_urlaub")
      .insert([{ Anfang: isoFormatAnfang, Ende: isoFormatEnde }]);
    if (error) console.error(error);
    fetchUsers();
  };

  function formatDateToGerman(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("de-DE", options);
  }

  const handleDeleteUser = async (userId) => {
    const { error } = await supabase
      .from("bees_urlaub")
      .delete()
      .eq("id", userId);
    if (error) console.error(error);
    fetchUsers();
  };

  const convertToISOFormat = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Monate sind von 0-11 in JS
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  return (
    <div className="events-container">
      <h2 className="events-title">Urlaub hinzufügen</h2>

      <div className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto" style={{paddingBottom:"20px" }}>
        <DatePicker
          id="datePicker-15"
          placeholder="Wählen sie eine Zeitspanne"
          selectionType="range"
          formatStyle="large"
          variant="double"
          value={range}
          onChange={value => setRange(value)}
        />
      </div>

      <button className="events-button" onClick={handleAddUser}>
        Add
      </button>

      <div className="events-title"></div>
      <ul className="events-list">
        {users.map((user) => (
          <li className="events-item-prv-Events" key={user.id}>
            {`${formatDateToGerman(user.Anfang)} bis ${formatDateToGerman(user.Ende)}`}
            <div className="vertical-line-prv-Veranstaltungen"></div>
            <button
              className="delete-btn"
              onClick={() => handleDeleteUser(user.id)}
            >
              Löschen
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Urlaub;
