import React from "react";
import "./team.css";

const TeamMember = ({ name, role, image }) => (
  <div className="team-member">
    <img src={image} alt={name} />
    <h3>{name}</h3>
    <p>{role}</p>
  </div>
);

const Team = () => {
  const teamMembers = [
    {
      name: "Anna Schmidt",
      role: "Entwicklerin",
      image: "https://via.placeholder.com/150",
    },
    {
      name: "Peter Müller",
      role: "Designer",
      image: "https://via.placeholder.com/150",
    },
    {
      name: "Peter Müller",
      role: "Designer",
      image: "https://via.placeholder.com/150",
    },
    {
      name: "Peter Müller",
      role: "Designer",
      image: "https://via.placeholder.com/150",
    },
    {
      name: "Peter Müller",
      role: "Designer",
      image: "https://via.placeholder.com/150",
    },
    // Fügen Sie hier weitere Teammitglieder hinzu...
  ];

  return (
    <div className="team-section">
      <h2>Unser Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default Team;
