// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-line-prv-Veranstaltungen {
  width: 3px; /* Breite der Linie */
  height: 40px; /* Höhe der Linie - Sie können diese Zahl nach Ihren Wünschen anpassen */
  background-color: #cccccc; /* Graue Farbe */
  margin: 0 15px;
}
.events-item-prv-Events {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}
@media (max-width: 450px) {
  .events-item-prv-Events {
    flex-direction: column;
  }
  .vertical-line-prv-Veranstaltungen {
    height: 2px;
    width: 100%;
    margin: 10px 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/container/Dashboard/components/trotzdem_offen/trotzdem_offen.css"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAE,qBAAqB;EACjC,YAAY,EAAE,wEAAwE;EACtF,yBAAyB,EAAE,gBAAgB;EAC3C,cAAc;AAChB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yCAAyC;AAC3C;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;IACX,WAAW;IACX,gBAAgB;EAClB;AACF","sourcesContent":[".vertical-line-prv-Veranstaltungen {\r\n  width: 3px; /* Breite der Linie */\r\n  height: 40px; /* Höhe der Linie - Sie können diese Zahl nach Ihren Wünschen anpassen */\r\n  background-color: #cccccc; /* Graue Farbe */\r\n  margin: 0 15px;\r\n}\r\n.events-item-prv-Events {\r\n  display: flex;\r\n  align-items: center;\r\n  background-color: #f9f9f9;\r\n  padding: 10px 15px;\r\n  margin-bottom: 10px;\r\n  border-radius: 5px;\r\n  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);\r\n}\r\n@media (max-width: 450px) {\r\n  .events-item-prv-Events {\r\n    flex-direction: column;\r\n  }\r\n  .vertical-line-prv-Veranstaltungen {\r\n    height: 2px;\r\n    width: 100%;\r\n    margin: 10px 0px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
