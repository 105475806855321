const translations = {
  en: {
    impressum: "Imprint",
    welcome:
      "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    about:
      "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    contact: "Contact",
    startseite: "Homepage",
    menu: "Menu",
    gutscheine:"Vouchers",
    veranstaltung: "Events",
    reservieren: "Reserve",
    karte: "Card",
    open: "Opening hours",
    ab17: "from 17:30",
    ruhetag: "Rest day",
    from11to15: "11:00 - 15:00 Uhr",
    montag: "Monday",
    dienstag: "Tuesday",
    mittwoch: "Wednesday",
    donnerstag: "Thursday",
    freitag: "Friday",
    samstag: "Saturday",
    sonntag: "Sunday",
    nextEvent: "Next event",
    yourEvent: "Your own event",
    yourEventText:"From Christmas parties to weddings, we offer the perfect setting for your private event.",
    instagram: "Follow us on Instagram",
    anfahrtHeading: "Direction",
    anfahrtText:
      "We are located in the historic Asbachgasse, in the heart of the former Asbach Uralt production site. Due to the location, directly opposite the train station in Rüdesheim, you can easily arrive by public transport. By car, you can use one of the numerous parking facilities in the area.",
    kontakt: "Contacts",
    sozialeMedien: "Social media",
    menuHeading: "Current menu",
    menuText:
      "Our menu is not built according to the classic approach. We in BEES Restaurant have decided to let you choose from the different flavors. The dishes of each category are based on them.",
    menü: "Menu",
    reservierenHeading: "RESERVATION REQUEST",
    reservierenText:
      "Ask simply and easily online for a table in our restaurant. Your reservation request will then be confirmed by us as soon as possible by phone or e-mail. Please note our opening hours.",
    vorname: "First name",
    nachname: "Last name",
    anschrift:"Address",
    gutscheinbetrag:"Amount of voucher",
    namedesBeschenkten:"Name of the gift recipient",
    selbstAbholen:"Pick up yourself",
    schickenLassen:"Let send",
    gutscheinBestellen:"Order voucher",
    telefon: "Phone number",
    amountOfPerson: "Number of people",
    datum: "Date",
    time: "Time",
    allergies: "Allergies/Intolerances",
    einverständnis:
      "I agree with the processing of the entered data and the privacy policy.",
    bestätigen: "Submit",
    menüWahl: "Select a menu",
    vierGängeMenü: "4 courses menu",
    fünfGängeMenü: "5 course menu",
    vollesMenü: "Full menu",
    gutscheinHeading:"Give the gift of unique moments in Bees Restaurant",
    gutscheinText1:"A smile, a thank you, a moment of surprise - you can give all this with our vouchers. They are not only a sign of appreciation, but also a promise for unforgettable hours in our restaurant. Whether for family, friends or special people in your life - with a voucher from Bees you are always right.",
    gutscheinText2:"We place great value on your privacy. If you contact us via this form, your data will be stored securely in order to process your request. Of course, we do not share your data and keep it only as long as necessary to process your request.",
    telefonrückfragen:"Telephon",
    rheinWeinWelt:"Discover the RheinWeinWelt",
    jetztAnfragen:"Request Now",
    prvVeranstaltung:"Private Events",
    prvVeranstaltungHeading:"Plan your own event!",
    prvVeranstaltungText:"Are you dreaming of an unforgettable event that exactly meets your expectations? Use our form to book your personal event and create an experience that will inspire you and your guests. Whether it's a birthday, anniversary or company celebration - we will turn your wishes into reality. Start planning now!",
    hundefreundlichHeading:"Dog friendly restaurant",
    hundefreundlichText:"We are dog friendly! Your four-legged friend is welcome to stay with us. Please keep him on a leash.",
  },
  de: {
    impressum:"Impressum",
    welcome:
      "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    about:
      "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    contact: "Kontakt",
    startseite: "Startseite",
    menu: "Speisekarte",
    gutscheine:"Gutscheine",
    veranstaltung: "Veranstaltungen",
    reservieren: "Reservieren",
    karte: "Karte",
    open: "Öffnungszeiten",
    ab17: "ab 17:30 Uhr",
    ruhetag: "Ruhetag",
    from11to15: "11:00 - 15:00 Uhr",
    montag: "Montag",
    dienstag: "Dienstag",
    mittwoch: "Mittwoch",
    donnerstag: "Donnerstag",
    freitag: "Freitag",
    samstag: "Samstag",
    sonntag: "Sonntag",
    nextEvent: "Nächste Veranstaltung",
    yourEvent: "Dein eigenes Event",
    yourEventText:"Von Weihnachtsfeier bis zur Hochzeit, wir bieten den perfekten Rahmen für Ihre private Veranstaltung.",
    instagram: "Folge uns auf Instagram",
    anfahrtHeading: "Anfahrt",
    anfahrtText:
      "Wir befinden uns in der historischen Asbachgasse, im Herzen der ehemaligen Asbach Uralt Produktionsstätte. Aufgrund der Lage, direkt gegenüber des Bahnhofs in Rüdesheim, können Sie bequem mit dem öffentlichen Nahverkehr anreisen. Mit dem Auto können Sie eine der zahlreichen Parkmöglichkeiten in der Umgebung nutzen.",
    kontakt: "Kontakte",
    sozialeMedien: "Soziale Medien",
    menuHeading: "Aktuelle Speisekarte",
    menuText:
      "Unsere Speisekarte baut sich nicht nach der klassischen Vorgehensweise auf. Wir in BEES Restaurant haben uns dazu entschieden, Sie aus den verschiedenen Geschmacksrichtungen wählen zu lassen. Die Speisen der jeweiligen Kategorie lehnen sich an diese an.",
    menü: "Menü",
    reservierenHeading: "RESERVIERUNGSANFRAGE",
    reservierenText:
      "Fragen Sie einfach und unkompliziert online nach einem Tisch in unserem Restaurant. Ihre Reservierungsanfrage wird anschließend schnellstmöglich per Telefon oder E-Mail durch uns bestätigt. Bitte beachten Sie unsere Öffnungszeiten.",
    vorname: "Vorname",
    nachname: "Nachname",
    anschrift:"Anschrift",
    gutscheinbetrag:"Gutscheinbetrag",
    namedesBeschenkten:"Name des Beschenkten",
    selbstAbholen:"Selbst abholen",
    schickenLassen:"Schicken lassen",
    gutscheinBestellen:"Gutschein Bestellen",
    telefon: "Telefonnummer",
    amountOfPerson: "Anzahl an Personen",
    datum: "Datum",
    time: "Uhrzeit",
    allergies: "Allergien/Unverträglichkeiten",
    einverständnis:
      "Ich erkläre mich mit der Verarbeitung der eingegebenen Daten sowie der Datenschutzerklärung einverstanden.",
    bestätigen: "Absenden",
    menüWahl: "Wähle ein Menü aus",
    vierGängeMenü: "4 Gänge Menü",
    fünfGängeMenü: "5 Gänge Menü",
    vollesMenü: "Volles Menü",
    gutscheinHeading:"Verschenken Sie einzigartige Momente im Bees Restaurant",
    gutscheinText1:"Ein Lächeln, ein Dankeschön, ein Moment der Überraschung – all das können Sie mit unseren Gutscheinen verschenken. Sie sind nicht nur ein Zeichen der Wertschätzung, sondern auch ein Versprechen für unvergessliche Stunden in unserem Restaurant. Egal ob für Familie, Freunde oder besondere Menschen in Ihrem Leben – mit einem Gutschein von Bees liegen Sie immer richtig.",
    gutscheinText2:"Wir legen großen Wert auf Ihre Privatsphäre. Wenn Sie uns über dieses Formular kontaktieren, werden Ihre Daten sicher gespeichert, um Ihre Anfrage bearbeiten zu können. Selbstverständlich geben wir Ihre Daten nicht weiter und bewahren sie nur so lange auf, wie es für die Bearbeitung Ihrer Anfrage notwendig ist.",
    telefonrückfragen:"Telefon",
    rheinWeinWelt:"Entdecken sie die RheinWeinWelt",
    jetztAnfragen:"Jetzt Anfragen",
    prvVeranstaltung:"Private Veranstaltung",
    prvVeranstaltungHeading:"Planen Sie Ihr eigenes Event!",
    prvVeranstaltungText:"Sie träumen von einem unvergesslichen Event, das genau Ihren Vorstellungen entspricht? Nutzen Sie unser Formular, um Ihr persönliches Event zu buchen und gestalten Sie ein Erlebnis, das Sie und Ihre Gäste begeistern wird. Egal ob Geburtstag, Jubiläum oder Firmenfeier – wir setzen Ihre Wünsche in die Tat um. Starten Sie jetzt mit der Planung!",
    hundefreundlichHeading:"Hundefreundliches Restaurant",
    hundefreundlichText:"Wir sind hundefreundlich! Ihr Vierbeiner ist bei uns herzlich willkommen. Bitte halten Sie ihn an der Leine.",
  },
};

export default translations;
