import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import supabase from "../../../LogIn/supabaseClient";
import Swal from "sweetalert2";
import "./changeMenu.css";

const ChangeMenu = () => {
  const showConfirmDialog = (fileName) => {
    return Swal.fire({
      title: "Upload-Bestätigung",
      text: `Sind Sie sicher, dass Sie die Datei ${fileName} hochladen möchten?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, laden Sie es hoch!",
    });
  };

  const showSuccessAlert = () => {
    Swal.fire("Erfolg!", "Daten erfolgreich hochgeladen!", "success");
  };

  const showErrorAlert = () => {
    Swal.fire(
      "Fehler!",
      "Beim Hochladen der Daten ist ein Fehler aufgetreten.",
      "error"
    );
  };

  const [errorMessage, setErrorMessage] = useState(null);

  const uploadToSupabase = async (data, tableName, file) => {
    try {
         const confirmResult = await showConfirmDialog(file.name);


      if (!confirmResult.isConfirmed) {
        return;
      }

      // Lösche den Inhalt der entsprechenden Tabelle
      let { error: deleteError } = await supabase.from(tableName).delete().neq("id", 0);

      if (deleteError) throw deleteError;

      // Füge das neue CSV-Ergebnis zur Tabelle hinzu
      let { error: insertError } = await supabase.from(tableName).insert(data);
      if (insertError) throw insertError;

      showSuccessAlert();
      console.log("Daten erfolgreich hochgeladen!");
    } catch (error) {
      console.error("Error uploading data:", error);
      showErrorAlert();
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    let tableName = "bees_speisekarte_de"; // Standardtabelle

    const file = acceptedFiles[0];

    if (file.name === "Bees_Karte_EN.xlsx") {
      tableName = "bees_speisekarte_en";
    } else if (file.name !== "Bees_Karte_DE.xlsx") {
      setErrorMessage(
        "Ungültiger Dateiname. Bitte laden Sie eine Datei mit dem Namen 'Bees_Karte_DE.xlsx' oder 'Bees_Karte_EN.xlsx' hoch."
      );
      return;
    }

    const reader = new FileReader();

    reader.onabort = () => console.warn("File reading was aborted");
    reader.onerror = () => console.warn("File reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const csv = XLSX.utils.sheet_to_csv(worksheet);
      Papa.parse(csv, {
        complete: (result) => {
          console.log("Parsed CSV Result:", result.data);
          setErrorMessage(null);
          const adjustedData = result.data.map((row) => {
            return {
              Karte: row[0],
              Name: row[1],
              Beschreibung: row[2],
              Kategorie: row[3],
              Preis: row[4],
              Extra: row[5],
            };
          });

          uploadToSupabase(adjustedData, tableName,file); // Übergeben Sie den Tabellennamen als Argument
        },
      });
    };

    reader.readAsBinaryString(file);
    // eslint-disable-next-line
  }, []); // Ein leeres Abhängigkeitsarray, da alles in der Callback-Funktion definiert ist

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
  });

  return (
    <div className="menu-container">
      <h2 className="events-title">Speisekarte aktualisieren</h2>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
      >
        <input {...getInputProps()} />
        <p>
          Ziehen Sie Ihre Excel-Datei per Drag & Drop hierher, oder klicken Sie,
          um eine auszuwählen.
        </p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <p className="info-hinweis">
        <b>Wichtiger Hinweis:</b> Bitte vergewissern Sie sich vor dem Hochladen,
        dass Ihre Datei das korrekte Format hat und den Anforderungen
        entspricht. Die Datei sollte den Namen <b>Bees_Karte_DE.xlsx</b> oder <b>Bees_Karte_EN.xlsx</b> tragen
        und korrekt strukturiert sein, wobei die Spalten{" "}
        <b>Karte, Name, Beschreibung, Kategorie, Preis und Extra</b> erwartet
        werden. Beachten Sie, dass beim Hochladen einer neuen Datei{" "}
        <i>
          alle vorhandenen Daten in der entsprechenden Tabelle überschrieben werden
        </i>
        . Nehmen Sie sich einen Moment Zeit, um die Datei vor dem Hochladen auf
        Richtigkeit zu überprüfen. So stellen Sie sicher, dass Ihre Speisekarte
        korrekt und fehlerfrei aktualisiert wird.
      </p>
    </div>
  );
};

export default ChangeMenu;
