import React from "react";
import "./gallery.css";
import RheinWeinWeltBadge from "../RheinWeinWeltBadge/rheinWeinWeltBadge";
import {
  BeesFood,
  BeesNaomi,
  BeesPralinen,
  BeesRene,
  BeesRestaurant,
  BeesRestaurant2,
} from "../../assets/gallery_images";

const Gallery = () => {
  return (
    <section className="app__bg-creme">
      <RheinWeinWeltBadge />
      <div className="app__gallery">
        <img className="app__gallery_img" src={BeesFood} alt="BeesFood" />
        <img className="app__gallery_img" src={BeesNaomi} alt="BeesNamoi" />
        <img
          className="app__gallery_img"
          src={BeesPralinen}
          alt="BeesPralinen"
        />
        <img className="app__gallery_img" src={BeesRene} alt="BeesRene" />
        <img
          className="app__gallery_img"
          src={BeesRestaurant}
          alt="BeesRestaurant"
        />
        <img
          className="app__gallery_img"
          src={BeesRestaurant2}
          alt="BeesRestaurant"
        />
      </div>
    </section>
  );
};

export default Gallery;
