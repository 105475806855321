import React from "react";
import "./events.css";
import supabase from "../../../LogIn/supabaseClient";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { DatePicker,HelpText } from 'react-rainbow-components';

const Events = () => {
  const [users, setUsers] = useState([]);

  const [Uhrzeit, setUhrzeit] = useState("");
  const [Name, setName] = useState("");
  const [Info, setInfo] = useState("");
  const [Info_Eng, setInfo_Eng] = useState("");
  const [Geschlossen, setGeschlossen] = useState("");
  const [inputDatum, setInputDatum] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    // Das aktuelle Datum im 'YYYY-MM-DD'-Format erhalten
    const currentDate = new Date().toISOString().split("T")[0];
  
    // Zuerst veraltete Datensätze löschen
    const { error: deleteError } = await supabase
      .from("bees_events")
      .delete()
      .lt("Datum", currentDate); 
    
    if (deleteError) {
      console.error("Fehler beim Löschen der abgelaufenen Datensätze:", deleteError);
    }
  
    // Dann alle Datensätze abfragen
    const { data, error: fetchError } = await supabase
      .from("bees_events")
      .select("*");
    
    if (data) setUsers(data);
    if (fetchError) console.error(fetchError);
  };
  

  const handleAddUser = async () => {
    const isoDate = convertToISOFormat(inputDatum);
    const { error } = await supabase
      .from("bees_events")
      .insert([{ Name, Datum: isoDate, Uhrzeit, Info, Info_Eng, Geschlossen }]);
    if (error) console.error(error);
    fetchUsers();
  };
  function formatDateToGerman(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("de-DE", options);
  }
  const handleDeleteUser = async (userId) => {
  Swal.fire({
    title: "Sind Sie sicher?",
    text: "Dieser Eintrag wird dauerhaft gelöscht!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Ja, löschen!",
    cancelButtonText: "Abbrechen",
  }).then(async (result) => {
    if (result.isConfirmed) {
      const { error } = await supabase
        .from("bees_events")
        .delete()
        .eq("Id", userId);
      if (error) console.error(error);
      fetchUsers();
      Swal.fire("Gelöscht!", "Ihr Eintrag wurde gelöscht.", "success");
    }
  });
};

  const convertToISOFormat = (germanDate) => {
    const parts = germanDate.split(".");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}.${month}.${day}`;
    }
    return ""; // Oder handle ungültige Daten wie gewünscht
  };
  const convertToValidDate = (germanDate) => {
    if (!germanDate) return null;
    const [day, month, year] = germanDate.split(".");
    return new Date(year, month - 1, day);
  };
  return (
    <div className="events-container">
      <h2 className="events-title">Add Event
        <HelpText
            variant="warning"
            title="Achtung!"
            style={{paddingLeft:"10px"}}
            text={<p>Bitte beachten Sie, dass sich die Daten automatisch löschen, nachdem der Tag vorbeigegangen ist.</p>}
        /></h2>
      <input
        placeholder="Name"
        value={Name}
        onChange={(e) => setName(e.target.value)}
        className="events-input"
      />
      <DatePicker
          id="event-date"
          formatStyle="medium"
          value={convertToValidDate(inputDatum)}
          style={{paddingBottom:"10px"}}
          onChange={value => {
              const formattedDate = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
              setInputDatum(formattedDate);
          }}
          placeholder="Datum"
      />
      <input
        placeholder="Uhrzeit"
        value={Uhrzeit}
        onChange={(e) => setUhrzeit(e.target.value)}
        className="events-input"
      />
      <input
        placeholder="Info"
        value={Info}
        onChange={(e) => setInfo(e.target.value)}
        className="events-input"
      />
      <input
        placeholder="Info Englisch"
        value={Info_Eng}
        onChange={(e) => setInfo_Eng(e.target.value)}
        className="events-input"
      />
      <select
        className="events-input"
        value={Geschlossen}
        onChange={(e) => setGeschlossen(e.target.value)}
      >
        <option value="" disabled>
          Geschlossen?
        </option>
        <option value="Ja">Ja</option>
        <option value="Nein">Nein</option>
      </select>
      <button className="events-button" onClick={handleAddUser}>
        Add
      </button>

      <div className="events-title"></div>
      <ul className="events-list">
        {users.map((user) => (
          <li className="events-item-Events" key={user.Id}>
            <div className="event-content">
              <h1>{user.Name}</h1>
              <div>{`am ${formatDateToGerman(user.Datum)} um ${
                user.Uhrzeit
              }`}</div>
              <div>DE:{user.Info}</div>
              <div>EN:{user.Info_Eng}</div>
              <div>{`Ist das Restaurant geschlossen? ${user.Geschlossen}`}</div>
            </div>
            <div className="vertical-line"></div>
            <button
              className="delete-btn"
              onClick={() => handleDeleteUser(user.Id)}
            >
              Löschen
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Events;