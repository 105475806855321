import React from "react";
import "./prvEvents.css";
import supabase from "../../../LogIn/supabaseClient";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { DatePicker } from 'react-rainbow-components';

const PrivateEvents = () => {
  const [users, setUsers] = useState([]);
  const [Geschlossen, setGeschlossen] = useState("");
  const [inputDatum, setInputDatum] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let { data, error } = await supabase
      .from("bees_prv_veranstaltung")
      .select("*");
    if (data) setUsers(data);
    if (error) console.error(error);
  };

  const handleAddUser = async () => {
    const isoFormat = convertToISOFormat(inputDatum);
    const { error } = await supabase
      .from("bees_prv_veranstaltung")
      .insert([{ Geschlossen, Datum: isoFormat }]);
    if (error) console.error(error);
    fetchUsers();
  };
  function formatDateToGerman(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("de-DE", options);
  }
  const handleDeleteUser = async (userId) => {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Dieser Eintrag wird dauerhaft gelöscht!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen!",
      cancelButtonText: "Abbrechen",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { error } = await supabase
          .from("bees_prv_veranstaltung")
          .delete()
          .eq("id", userId);
        if (error) console.error(error);
        fetchUsers();
        Swal.fire("Gelöscht!", "Ihr Eintrag wurde gelöscht.", "success");
      }
    });
  };
  
  const convertToISOFormat = (germanDate) => {
    const parts = germanDate.split(".");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}.${month}.${day}`;
    }
    return ""; // Oder handle ungültige Daten wie gewünscht
  };
  const convertToValidDate = (germanDate) => {
    if (!germanDate) return null;
    const [day, month, year] = germanDate.split(".");
    return new Date(year, month - 1, day);
  };
return (
    <div className="events-container">
      <h2 className="events-title">Add private Veranstaltung</h2>
      <DatePicker
          id="event-date"
          formatStyle="medium"
          style={{paddingBottom:"20px"}}
          value={convertToValidDate(inputDatum)}
          placeholder="Datum der Veranstaltung"
          onChange={value => {
              const formattedDate = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
              setInputDatum(formattedDate);
          }}
      />
      <select
        className="events-input"
        value={Geschlossen}
        onChange={(e) => setGeschlossen(e.target.value)}
      >
        <option value="" disabled>
          Geschlossen?
        </option>
        <option value="Ja">Ja</option>
        <option value="Nein">Nein</option>
      </select>

      <button className="events-button" onClick={handleAddUser}>
        Add
      </button>

      <div className="events-title"></div>
      <ul className="events-list">
        {users.map((user) => (
          <li className="events-item-prv-Events" key={user.id}>
            {formatDateToGerman(user.Datum)}
            {` | Ist das Restaurant geschlossen? ${user.Geschlossen}`}
            <div className="vertical-line-prv-Veranstaltungen"></div>
            <button
                className="delete-btn"
                onClick={() => handleDeleteUser(user.id)}
              >
                Löschen
              </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default PrivateEvents;
