// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 50px;
}

.app__gallery_img {
  width: calc(33.33% - 10px); /* Drei Bilder pro Zeile */
  aspect-ratio: 1/1; /* Erzwingt ein quadratisches Seitenverhältnis */
  border-radius: 10px;
  object-fit: cover;
  padding: 3px;
}

@media screen and (max-width: 450px) {
  .app__gallery {
    flex-wrap: wrap; /* Behält die Umbruchfunktion für schmalere Bildschirme bei */
    padding: 25px;
  }

  .app__gallery_img {
    width: calc(50% - 10px); /* Zwei Bilder nebeneinander */
    padding: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/container/Gallery/gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,aAAa;AACf;;AAEA;EACE,0BAA0B,EAAE,0BAA0B;EACtD,iBAAiB,EAAE,gDAAgD;EACnE,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE;IACE,eAAe,EAAE,6DAA6D;IAC9E,aAAa;EACf;;EAEA;IACE,uBAAuB,EAAE,8BAA8B;IACvD,UAAU;EACZ;AACF","sourcesContent":[".app__gallery {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  padding: 50px;\n}\n\n.app__gallery_img {\n  width: calc(33.33% - 10px); /* Drei Bilder pro Zeile */\n  aspect-ratio: 1/1; /* Erzwingt ein quadratisches Seitenverhältnis */\n  border-radius: 10px;\n  object-fit: cover;\n  padding: 3px;\n}\n\n@media screen and (max-width: 450px) {\n  .app__gallery {\n    flex-wrap: wrap; /* Behält die Umbruchfunktion für schmalere Bildschirme bei */\n    padding: 25px;\n  }\n\n  .app__gallery_img {\n    width: calc(50% - 10px); /* Zwei Bilder nebeneinander */\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
