import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { FlagIcon } from "react-flag-kit";
import { useLanguage } from "../../language/currentLanguage";
import translations from "../../language/translation";
import supabase from "../../container/LogIn/supabaseClient";
import { useAuth } from "../../container/logInProvider/logInProvider";

import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleTranslation, settoggleTranslation] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  // Handle scroll event
  const handleScroll = () => {
    setIsScrolled(window.scrollY > window.innerHeight - 1);
  };

  const toggleTranslationDropdown = () => {
    settoggleTranslation((prev) => !prev);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Fehler beim Ausloggen:", error);
    } else {
      setIsLoggedIn(false);
      // Optional: Weiterleitung nach dem Logout
      // navigate("/login");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const { setSelectedLanguage } = useLanguage();

  return (
    <nav className="app__navbar">
      <div className="app__navbar_container-icons">
        <GiHamburgerMenu
          color={isScrolled ? "black" : "#fef4e2"}
          fontSize={50}
          onClick={() => setToggleMenu(true)}
          className="app__navbar_hamburgerMenu"
        />
        <div className="app__navbar_logOut">
          {isLoggedIn && (
              <a
              href="/dashboard"
              className={`${
                isScrolled ? "hide" : ""
              } app__navbar_dashboard-button`}
              >
                Dashboard  |
              </a>
            )}
          {isLoggedIn && (
            <a
              href="#login"
              onClick={handleLogout}
              className={`${
                isScrolled ? "hide" : ""
              } app__navbar_logOut-button`}
            >
                Log Out 
            </a>
          )}
          <FlagIcon //Hier die Flagge ändern wenn im LocalStorage die selectedlanguage variable en oder de ist.
            code={selectedLanguage === "de" ? "DE" : "GB"}
            color="#fef4e2"
            fontSize={50}
            onClick={() => settoggleTranslation((prev) => !prev)} // Hier muss die Funktion für die Übersetzung aufgerufen werden
            className={`${
              isScrolled ? "hide" : ""
            } app__navbar_translateIcon app__navbar_flaggen`}
          />
        </div>
      </div>
      {toggleTranslation && (
        <div className="app__navbar_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu
            fontSize={50}
            className="overlay__close"
            onClick={toggleTranslationDropdown}
          />
          <ul className="app__navbar_links">
            <li>
              <a
                href="#de"
                onClick={() => {
                  toggleTranslationDropdown();
                  setSelectedLanguage("de");
                }}
              >
                <FlagIcon className="app__navbar_flaggen" code="DE" /> Deutsch
              </a>
            </li>
            <li>
              <a
                href="#en"
                onClick={() => {
                  toggleTranslationDropdown();
                  setSelectedLanguage("en");
                }}
              >
                <FlagIcon className="app__navbar_flaggen" code="GB" /> English
              </a>
            </li>
          </ul>
        </div>
      )}

      {toggleMenu && (
        <div className="app__navbar_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu
            fontSize={50}
            className="overlay__close"
            onClick={() => setToggleMenu(false)}
          />
          <ul className="app__navbar_links">
            <li>
              <a href="/" onClick={() => setToggleMenu(false)}>
                {translation.startseite}
              </a>
            </li>
            <li>
              <a href="/Speisekarte" onClick={() => setToggleMenu(false)}>
                {translation.menu}
              </a>
            </li>
            <li>
              <a href="/Reservierung" onClick={() => setToggleMenu(false)}>
                {translation.reservieren}
              </a>
            </li>
            <li>
              <a href="/Veranstaltungen" onClick={() => setToggleMenu(false)}>
                {translation.veranstaltung}
              </a>
            </li>
            <li>
              <a href="/Gutscheine" onClick={() => setToggleMenu(false)}>
                {translation.gutscheine}
              </a>
            </li>
            <li>
             {/*<a href="/team" onClick={() => setToggleMenu(false)}>
                Team
              </a>*/}
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
