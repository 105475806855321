import React, { useEffect, useState } from "react";
import axios from "axios";
import "./instagramSection.css";
import { BsInstagram } from "react-icons/bs";
import Cookies from "js-cookie";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import supabase from "../LogIn/supabaseClient";
import { event } from "react-ga";

const InstagramSection = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const postsKey = "cachedInstagramPosts";
    const expirationKey = "postsExpiration";
    const expirationTime = 24 * 60 * 60 * 1000;
    const { selectedLanguage } = useLanguage();
    const translation = translations[selectedLanguage];

    const handleGoogleAnalyticsInstagramSectionLinks = () => {
      // Tracke Link-Klick-Event
      event({
        category: 'Link',
        action: 'Click',
        label: 'Instagram Section Links'
      });
    };
    const handleBothClicks = (e) => {
      incrementInstagramCount(e);
      handleGoogleAnalyticsInstagramSectionLinks(e);
    };

    const getCurrentWeekNumber = () => {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
      const week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
  };  

    const incrementInstagramCount = async () => {
        if (sessionStorage.getItem("instagramClicked")) {
            return;
        }

        const currentYear = new Date().getFullYear();
        const currentWeek = getCurrentWeekNumber();
        const { data, error } = await supabase
            .from("bees_tracking_week")
            .select("Instagram")
            .eq('Kw', currentWeek)
            .eq('Jahr', currentYear);

        if (error) {
            console.error("Error fetching current Instagram count:", error);
            return;
        }

        const currentCount = data[0].Instagram;

        const { error: updateError } = await supabase
            .from("bees_tracking_week")
            .update({ Instagram: currentCount + 1 })
            .eq('Kw', currentWeek)
            .eq('Jahr', currentYear);

        if (updateError) {
            console.error("Error updating Instagram count:", updateError);
        } else {
            sessionStorage.setItem("instagramClicked", "true");
        }
    };

  const fetchInstagramPosts = async () => {
    try {
      const accessToken = process.env.REACT_APP_INSTAGRAM_TOKEN;
      const limit = 4; // Set the limit to 4 posts
      const response = await axios.get(
        `https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink&access_token=${accessToken}&limit=${limit}`
      );
      const newPosts = response.data.data;
      setPosts(newPosts);
      setLoading(false);
      // Setze das Cookie mit den Attributen SameSite=None und Secure
      Cookies.set("instagramPosts", JSON.stringify(newPosts), {
        sameSite: "None",
        secure: true,
      });
      localStorage.setItem(postsKey, JSON.stringify(newPosts));
      localStorage.setItem(expirationKey, Date.now() + expirationTime);
    } catch (error) {
      setError("Fehler beim Abrufen der Instagram-Posts.");
      setLoading(false);
    }
  };

  useEffect(() => {
    const cachedPosts = JSON.parse(localStorage.getItem(postsKey));
    const expiration = localStorage.getItem(expirationKey);

    if (cachedPosts && expiration && Date.now() < expiration) {
      setPosts(cachedPosts);
      setLoading(false);
    } else {
      fetchInstagramPosts();
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="app__bg-creme">
        <div className="app__instagramSection_container">
            <div className="app__instagramSection_heading">
                {translation.instagram}
            </div>
            <div className="app__instagramSection_image-container">
                {loading ? (
                    <p>Lade Instagram-Posts...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    posts.slice(0, windowWidth <= 600 ? 2 : 4).map((post) => (
                        <div className="app__instagramSection_image" key={post.id}>
                            <a
                                href={post.permalink}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={handleBothClicks}
                            >
                                <BsInstagram className="instagram-icon" />
                                <img src={post.media_url} alt={post.caption} />
                            </a>
                        </div>
                    ))
                )}
            </div>
        </div>
    </section>
);
};

export default InstagramSection;





