import React from "react";
import { BsInstagram, BsFacebook, BsPhone, BsEnvelope } from "react-icons/bs";
import { DogFriendly } from "../../assets/web_images";
import "./footer.css";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import { Notification } from "react-rainbow-components";
import { FaDog } from "react-icons/fa";
import { event } from "react-ga";

const Footer = () => {
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  const [showNotification, setShowNotification] = React.useState(false);

  const dogIconStyles = {
    backgroundColor: '#003f64', // Grauer Hintergrund
    borderRadius: '50%', // Kreisförmiger Hintergrund
    padding: "3px",
    color:"white"
};

const trackInstagramClick = () => {
  event({
    category: 'Social Media',
    action: 'Footer Instagram Button Clicked',
  });
};

const trackFacebookClick = () => {
  event({
    category: 'Social Media',
    action: 'Footer Facebook Button Clicked',
  });
};

  // Funktion, um die Benachrichtigung anzuzeigen und nach 5 Sekunden auszublenden
  const handleShowNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <footer className="app__bg-blue">
      <div className="app__footer_container">
        <div className="app__footer_section">
          <div className="app__footer_container_heading">
            {translation.kontakt}
          </div>
          <div className="app__footer_icons">
            <a href="tel:+49 6722 4191916">
              <BsPhone className="footer-icon" />
            </a>
            <a href="mailto:info@beesrestaurant.de">
              <BsEnvelope className="footer-icon" />
            </a>
          </div>
        </div>
        <div className="app__footer_section">
          <a href="/impressum" className="app__footer_container_heading">
            {translation.impressum}
          </a>
          <div className="app__footer_icons">
            <a href="#dog" onClick={handleShowNotification}>
              <img src={DogFriendly} alt="DogFriendly" />
            </a>
            {/* Benachrichtigung anzeigen, wenn showNotification true ist */}
            {showNotification && (
                  <div className="rainbow-p-right_small rainbow-flex rainbow-flex_column rainbow-align_end" style={{ position: 'fixed', top: 5, right: 5, zIndex: 1000 }}>
                     <Notification
                          title={translation.hundefreundlichHeading}
                          description={translation.hundefreundlichText}
                          icon={
                            <FaDog style={dogIconStyles} size="2.5em" color="white" />
                          }
                          hideCloseButton={true}
                      />
                  </div>
              )}
          </div>
        </div>
        <div className="app__footer_section">
          <div className="app__footer_container_heading">
            {translation.sozialeMedien}
          </div>
          <div className="app__footer_icons">
          <a
              href="https://www.instagram.com/bees.restaurant/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackInstagramClick}
            >
              <BsInstagram className="footer-icon" />
            </a>
            <a
              href="https://www.facebook.com/beesrestaurantruedesheim/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={trackFacebookClick}
            >
              <BsFacebook className="footer-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
