import React, { useState } from "react";
import { Button, Input, CheckboxToggle} from "react-rainbow-components";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { event } from "react-ga";

const SERVICE_ID = "service_rn4ywvd";
const TEMPLATE_ID = "template_ivmdkhd";//hier muss noch die richtige Template-Id hinzugeefügt werden
const USER_ID = "SqPLX_yZBQRndzUy3";

const Gutscheine = () => {
  const [vorname, setVorname] = useState("");
  const [nachname, setNachName] = useState("");
  const [anschrift, setAnschrift] = useState("");
  const [email, setEmail] = useState("");
  const [investition, setInvestition] = useState("");
  const [beschenktenName, setBeschenktenName] = useState("");
  const [auswahl, setAuswahl] = useState(""); // "abholen", "schicken" oder ""
  // eslint-disable-next-line
  const [phone, setPhone] = useState("");
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];

  
  const handleGoogleAnalyticsGutscheineSubmits = () => {
    // Tracke Button-Klick-Event
    event({
      category: 'Button',
      action: 'Click',
      label: 'Submit Button Gutscheine', 
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.user_liefervariante.value = auswahl;
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: `${selectedLanguage === "de" ? "Nachricht erfolgreich versendet." : "Message successfully sent."}`,
      });
      
    },
    (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: `${selectedLanguage === "de" ? "Uups, etwas ist schief gelaufen." : "Ooops, something went wrong"}`,
          text: error.text,
      });
      
    }
    );
  };

  return (
    <div className="app__bg-creme" style={{padding:"20px"}}>
    <div style={{
      maxWidth: "800px",
      margin: "0 auto",
      padding: "2rem",
      boxShadow: "0 4px 15px rgba(0,0,0,0.999)",
      borderRadius: "15px",
      backgroundColor: "var(--color-creme)",
      fontFamily: "SawtonIndustrial-Light"
    }}>
      <h2 style={{
        textAlign: "center",
        color: "var(--color-dark-blue)",
        fontSize:"2rem",
        marginBottom: "2rem",
        borderBottom: "2px solid var(--color-blue)",
        paddingBottom: "1rem"
      }}>
        {translation.gutscheinHeading}
      </h2>
      <p style={{ color: "var(--color-blue)", marginBottom: "1rem",fontSize:"1.25rem", textAlign:"center",}}>
        {translation.gutscheinText1}
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          id="form_firstname"
          name="user_firstname"
          placeholder={`${translation.vorname}...`}
          type="text"
          style={{padding:"5px"}}
          value={vorname}
          required
          onChange={(e) => setVorname(e.target.value)}
        />
        <Input
          id="form_lastname"
          name="user_lastname"
          placeholder={`${translation.nachname}...`}
          type="text"
          style={{padding:"5px"}}
          value={nachname}
          required
          onChange={(e) => setNachName(e.target.value)}
        />
        <Input
          id="form_adress"
          name="user_adress"
          placeholder={translation.anschrift}
          type="text"
          style={{padding:"5px"}}
          value={anschrift}
          onChange={(e) => setAnschrift(e.target.value)}
        />
        <Input
          placeholder="E-Mail"
          type="email"
          id="user_email"
          name="user_email"
          style={{padding:"5px"}}
          value={email}
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
            id="form_phoneNumber"
            name="user_number"
            style={{padding:"5px"}}
            placeholder={translation.telefonrückfragen}
            type="phone"
            onChange={(e) => setPhone(e.target.value)}
            required
            value={phone}
        />
        <Input
          placeholder={translation.gutscheinbetrag}
          id="form_gutscheinbetrag"
          name="user_gutscheinbetrag"
          type="text"
          style={{padding:"5px"}}
          value={investition}
          required
          onChange={(e) => setInvestition(e.target.value)}
        />
        <Input
          placeholder={translation.namedesBeschenkten}
          type="text"
          id="form_namedesBeschenkten"
          name="user_namedesBeschenkten"
          style={{padding:"5px"}}
          required
          value={beschenktenName}
          onChange={(e) => setBeschenktenName(e.target.value)}
        />
        <input
          type="hidden"
          name="user_liefervariante"
          id="user_liefervariante"
        />
         <CheckboxToggle
          label={translation.selbstAbholen}
          style={{paddingLeft:"10px"}}
          value={auswahl === "abholen"}
          required
          onChange={() => setAuswahl(auswahl === "abholen" ? "" : "abholen")}
          />
          <CheckboxToggle
            label={translation.schickenLassen}
            style={{padding:"10px"}}
            value={auswahl === "schicken"}
            required
            onChange={() => setAuswahl(auswahl === "schicken" ? "" : "schicken")}
          />
        <Button
        onClick={handleGoogleAnalyticsGutscheineSubmits}
          label={translation.gutscheinBestellen}
          variant="brand"
          type="submit"
          style={{
            width: "100%",
            backgroundColor: "var(--color-blue)",
            border: "none",
            color: "var(--color-white)",
            fontSize:"1.25rem",
          }}
        />
      </form>
      <p style={{ color: "var(--color-blue)", marginTop: "1rem",fontSize:"1.25rem",  textAlign:"center"}}>
      {translation.gutscheinText2}
      </p>
    </div>
    </div>
  );
};

export default Gutscheine;
