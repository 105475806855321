import React from 'react';
import './rheinWeinWeltBadge.css';
import { RheinWeinWeltLogo } from '../../assets/web_images';
import { useLanguage } from '../../language/currentLanguage';
import translations from '../../language/translation';
import { event } from 'react-ga';

const RheinWeinWeltBadge = () => {
    const {selectedLanguage} = useLanguage()
    const translation = translations[selectedLanguage]

    const handleGoogleAnalyticsRheinWeinWeltLink = () => {
        // Tracke Link-Klick-Event
        event({
          category: 'Link',
          action: 'Click',
          label: 'Rhein Wein Welt Link',
        });
      };

    return (
        <div className="RheinWeinWelt-badge-wrapper">
            <a onClick={handleGoogleAnalyticsRheinWeinWeltLink} href="https://www.rheinweinwelt.de/de/" rel="noreferrer" target="_blank" title="Zur Website von RheinWeinWelt Restaurant">
                <div className="RheinWeinWelt-badge-content">
                    <div className="RheinWeinWelt-text">
                        <span>
                            {translation.rheinWeinWelt}
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                        </span>
                    </div>
                    <div className="RheinWeinWelt-image">
                        <img src={RheinWeinWeltLogo} alt="RheinWeinWelt Logo" />
                    </div>
                </div>
            </a>
        </div>
    );
}

export default RheinWeinWeltBadge;
