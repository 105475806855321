import React, { useState, useEffect } from "react";
import supabase from "../LogIn/supabaseClient";
import emailjs from "emailjs-com";
import { Form } from "semantic-ui-react";
import Swal from "sweetalert2";
import "./contactForm.css";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import { Input } from 'react-rainbow-components';
import { Textarea } from 'react-rainbow-components';
import { Select } from 'react-rainbow-components';
import { DatePicker } from 'react-rainbow-components';
import { CounterInput } from 'react-rainbow-components';
import { CheckboxToggle } from 'react-rainbow-components';
// import { PhoneInput } from 'react-rainbow-components';
import { event } from "react-ga";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;


const Contactform = () => {
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [showExtraDropdown, setShowExtraDropdown] = useState(false);
  const [closedDates, setClosedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [checkboxToggled, setcheckboxToggled] = useState(false)
   // eslint-disable-next-line
  const [phone, setPhone] = useState([]);
  const [vacationDates, setVacationDates] = useState([]);
  const [prvVeranstaltungDates, setPrvVeranstaltungDates] = useState([]);
  const [generatedDisabledDays, setGeneratedDisabledDays] = useState([]);


  const handleGoogleAnalyticsReservierenSubmits = () => {
    // Tracke Button-Klick-Event
    event({
      category: 'Button',
      action: 'Click',
      label: 'Submit Button Reservieren', 
    });
  };

  const ZusatztextReservieren = "wir möchten uns herzlich für Ihre Anfrage bezüglich einer Reservierung im Bees bedanken. Es freut uns, dass Sie unser Restaurant für Ihr geplantes Treffen ausgewählt haben.<br/>br/>Ihre Anfrage wurde erfolgreich empfangen und wir arbeiten daran, Ihre Reservierungswünsche entsprechend zu bearbeiten. Unsere Mitarbeiter werden sich bald mit Ihnen in Verbindung setzen, um die Details Ihrer Reservierung zu bestätigen und etwaige weitere Fragen zu klären.<br/><br/>Hier ist eine Zusammenfassung der von Ihnen übermittelten Informationen:";
  const ZusatztextReservieren2 = "Bitte überprüfen Sie diese Informationen und stellen Sie sicher, dass alles korrekt ist. Sollten Sie Änderungen oder Ergänzungen haben, zögern Sie bitte nicht, uns dies mitzuteilen.<br/><br/>Wir freuen uns darauf, Sie bald bei uns begrüßen zu dürfen.<br/><br/>Mit freundlichen Grüßen,<br/><br/>Ihr Bees-Team<br/>Am Rottland 6, 65385 Rüdesheim am Rhein<br/>Kontakt: 06722 4191916";
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setSelectedDate(dateValue);

    const [day, month] = dateValue.split(".");
    const year = new Date().getFullYear();
    const dayOfWeek = new Date(year, month - 1, day).getDay();

    if (dayOfWeek === 0) {
      // 0 steht für Sonntag
      setShowExtraDropdown(false);
    } else if (numberOfPeople >= 8) {
      setShowExtraDropdown(true);
    }
  };

  useEffect(() => {
    const fetchClosedDates = async () => {
      let { data, error } = await supabase
        .from("bees_events")
        .select("Datum")
        .eq("Geschlossen", "Ja");
      if (data) setClosedDates(data.map((item) => item.Datum));
      if (error) console.error(error);
    };

    fetchClosedDates();
  }, []);
  useEffect(() => {
    const fetchPrvVeranstaltungDates = async () => {
      let { data, error } = await supabase
        .from("bees_prv_veranstaltung")
        .select("Datum")
        .eq("Geschlossen", "Ja");
      if (data) setPrvVeranstaltungDates(data.map((item) => item.Datum));
      if (error) console.error(error);
    };

    fetchPrvVeranstaltungDates();
  }, []);

  const getCurrentWeekNumber = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    const week1 = new Date(date.getFullYear(), 0, 4);
    return 1 + Math.round(((date - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
};

useEffect(() => {
  // Urlaubsdaten abrufen
  const fetchVacationDates = async () => {
      let { data, error } = await supabase.from("bees_urlaub").select("Anfang, Ende");
      if (data) {
          const disabledDates = [];
          data.forEach(vacation => {
              const startDate = new Date(vacation.Anfang);
              const endDate = new Date(vacation.Ende);
              
              let currentDate = new Date(startDate);
              while (currentDate <= endDate) {
                  disabledDates.push(currentDate.toISOString().split('T')[0]);
                  currentDate.setDate(currentDate.getDate() + 1);
              }
          });
          setVacationDates(disabledDates);
      }
      if (error) {
          console.error("Error fetching vacation dates:", error);
      }
  };

  fetchVacationDates();
  // ... (andere useEffect Logik)
}, []);

const incrementReservationCount = async () => {
  const currentYear = new Date().getFullYear();
  const currentWeek = getCurrentWeekNumber();

  let { data, error } = await supabase
      .from("bees_tracking_week")
      .select("Reservierungen")
      .eq('Kw', currentWeek)
      .eq('Jahr', currentYear);

  if (error) {
      console.error("Error fetching reservation count:", error);
      return;
  }

  if (data && data.length > 0) {
      const currentCount = data[0].Reservierungen || 0;
      const updatedCount = currentCount + 1;

      let { error: updateError } = await supabase
          .from("bees_tracking_week")
          .update({ Reservierungen: updatedCount })
          .eq('Kw', currentWeek)
          .eq('Jahr', currentYear);

      if (updateError) {
          console.error("Error updating reservation count:", updateError);
      }
  }
};

  const handleOnSubmit = (e) => {
    e.preventDefault();
    e.target.user_zusatztextReserve.value = ZusatztextReservieren;
    e.target.user_zusatztextReserve2.value = ZusatztextReservieren2

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      async (result) => {
          console.log(result.text);
          await incrementReservationCount(); // Hier rufen wir die Funktion auf
          Swal.fire({
            icon: "success",
            title: `${selectedLanguage === "de" ? "Nachricht erfolgreich versendet." : "Message successfully sent."}`,
        });
        
      },
      (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: `${selectedLanguage === "de" ? "Uups, etwas ist schief gelaufen." : "Ooops, something went wrong"}`,
            text: error.text,
        });
        
      }
  );  
    e.target.reset();
};

const getTimeOptions = (sundayTimes = { start: "11:00", end: "14:15" }, weekdayTimes = { start: "17:30", end: "20:45" }, intervalMinutes = 15) => {
  if (!selectedDate) return []; // Wenn selectedDate null oder undefined ist, geben Sie ein leeres Array zurück

  const [day, month, year] = selectedDate.split(".");
  const dayOfWeek = new Date(year, month - 1, day).getDay();

  const { start, end } = dayOfWeek === 0 ? sundayTimes : weekdayTimes;  // Wähle die Zeiten basierend auf dem Wochentag

  const [startHour, startMinute] = start.split(":").map(Number);
  const [endHour, endMinute] = end.split(":").map(Number);

  const options = [];
  for (let hour = startHour; hour <= endHour; hour++) {
      const minMinute = hour === startHour ? startMinute : 0;
      const maxMinute = hour === endHour ? endMinute : 45;
      for (let minute = minMinute; minute <= maxMinute; minute += intervalMinutes) {
          const time = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
          options.push({
              value: time,
              label: time
          });
      }
  }
  return options;
};


    // Funktion, um alle Dienstage und Mittwoche für ein bestimmtes Jahr zu generieren
    async function generateDisabledDaysForYear(year) {
      const disabledDays = [];
      let exceptionDates = [];
    
      // Supabase-Abfrage, um die Ausnahmedaten zu erhalten
      try {
        const { data, error } = await supabase
          .from('bees_trotzdem_offen') 
          .select('*');
    
        if (error) throw error;
        // Konvertieren Sie die empfangenen Daten in das erforderliche Format
        exceptionDates = data.map(item => item.Datum);
      } catch (error) {
        console.error('Fehler beim Abrufen der Ausnahmedaten:', error);
      }
    
      for (let month = 0; month < 12; month++) {
        for (let day = 1; day <= 31; day++) {
          const date = new Date(year, month, day);
          if (date.getMonth() !== month) continue; // Überprüfen, ob das Datum gültig ist
    
          const dateString = `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    
          // Überprüfen Sie, ob das Datum ein Dienstag oder Mittwoch ist und nicht in den Ausnahmedaten enthalten ist
          if ((date.getDay() === 2 || date.getDay() === 3) && !exceptionDates.includes(dateString)) {
            disabledDays.push(dateString);
          }
        }
      }
    
      return disabledDays;
    }
    
    const convertToValidDate = (dateString) => {
      if (!dateString) return null;
      const [day, month, year] = dateString.split(".");
      return new Date(year || new Date().getFullYear(), month - 1, day);
  };

  const containerStyles = {
    maxWidth: 1440,          //inputfield style
  };

  const optionsMenü = [
    {value:"4-gang-menu", label:`${translation.vierGängeMenü}`},
    {value:"5-gang-menu", label:`${translation.fünfGängeMenü}`},
    {value:"full-menu",label: `${translation.vollesMenü}`}
  ];
  

  const maxLimit = 10;
  const handleAmountOfPeopleChange = value => {
  
    if (!value) return;
    if (value > maxLimit) setNumberOfPeople(maxLimit.toString());
    else setNumberOfPeople(value.toString());

    if (value >= 7) {
        setShowExtraDropdown(true);
    } else {
        setShowExtraDropdown(false);
    }
}
useEffect(() => {
  const fetchDisabledDays = async () => {
    const currentYear = new Date().getFullYear();
    const disabledDaysThisYear = await generateDisabledDaysForYear(currentYear);
    const disabledDaysNextYear = await generateDisabledDaysForYear(currentYear + 1);

    setGeneratedDisabledDays([
      ...disabledDaysThisYear,
      ...disabledDaysNextYear
    ]);
  };

  fetchDisabledDays();
}, []); // Fügen Sie alle Abhängigkeiten hinzu, die die generierten deaktivierten Tage beeinflussen könnten


  return (
    <section className="contactform">
      <Form onSubmit={handleOnSubmit}>
        <div className="labelInput">{translation.vorname}</div>
        <input type="hidden" id="form_zusatztext" name="user_zusatztextReserve" value="" />
        <input type="hidden" id="form_zusatztext2" name="user_zusatztextReserve2" value="" />
        <Input
        id="form_firstname"
        name="user_firstname"
        placeholder={`${translation.vorname}...`}
        style={containerStyles}
        required
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        {/* Weitere Eingabefelder hier einfügen, ähnlich wie bei "Name" und "Nachricht" */}
        <div className="labelInput">{translation.nachname}</div>
        <Input
        id="form_lastname"
        name="user_lastname"
        placeholder={`${translation.nachname}...`}
        style={containerStyles}
        required
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        <div className="labelInput">E-Mail</div>
        <Input
        id="form_email"
        name="user_email"
        placeholder="E-Mail…"
        style={containerStyles}
        required
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        
        <div className="labelInput">{translation.telefon}</div>
        <Input
            id="form_phoneNumber"
            name="user_number"
            style={containerStyles}
            placeholder={`${translation.telefon}...`}
            required
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        <div className="labelInput">{translation.amountOfPerson}</div>
        <CounterInput
          id="form_amountOfPerson"
          name="user_amountOfPeople"
          style={containerStyles}
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
          labelAlignment="center"
          value={numberOfPeople}
          onChange={handleAmountOfPeopleChange}
          max={maxLimit}
          min={1}
          size="medium"
          placeholder={translation.amountOfPerson}
          required
          
        />
        {showExtraDropdown && (
          <div>
            <p className="labelInput">{translation.menüWahl}</p>
            <Select
            id="form_additionalMenuWahl"
            name="user_additionalMenuWahl"
            required
            options={optionsMenü}
            style={containerStyles}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
            />
          </div>
        )}
        <div className="labelInput">{translation.datum}</div>
          <DatePicker
            id="form_date"
            name="user_date"
            style={containerStyles}
            required
            value={convertToValidDate(selectedDate)}
            onChange={value => {
                const formattedDate = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
                handleDateChange({ target: { value: formattedDate } });
            }}
            minDate={new Date()} // Optional: to ensure past dates cannot be selected
            maxDate={new Date(new Date().getFullYear() + 1, 11, 31)} // Optional: to limit the date to this year
            disabledDays={[
              ...closedDates,
              ...vacationDates,
              ...prvVeranstaltungDates,
              ...generatedDisabledDays
            ]}
          />


        <div className="labelInput">{translation.time}</div>
        <div className="time-dropdown-container">
          <Select
            id="form_time"
            name="user_time"
            required
            options={getTimeOptions()}
            style={containerStyles}
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />

        </div>
        <div className="labelInput">{translation.allergies}</div>
        <Textarea
        id="form_allergies"
        name="user_allergies"
        rows={4}
        placeholder={`${translation.allergies}...`}
        style={containerStyles}
        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        <div className="app__contactForm_Checkbox">
        <CheckboxToggle
                id="form_consent"
                name="user_consent"
                value={checkboxToggled}
                required
                onClick={()=>{setcheckboxToggled(prev => !prev)}}
            />
          <div className="labelInput" style={{ padding:"5px 10px", textAlign: "center"}}>{translation.einverständnis}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button onClick={handleGoogleAnalyticsReservierenSubmits} className="submit-button-reserve" type="submit">
            {translation.bestätigen}
          </button>
        </div>
      </Form>
    </section>
  );
};

export default Contactform;
