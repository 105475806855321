import React, { useEffect, useState } from "react"; // useState hinzugefügt
import "./landingPage.css";
import { useLocation } from "react-router-dom";
import {
  BeesEvent,
  BeesImpressum,
  BeesReservierne,
  BeesRestaurant,
  BeesSpeisekartre,
  BeesVeranstaltungen,
  BeesEvents,
  BeesImprint,
  BeesMenu,
  BeesReserve,
} from "../../assets/web_images/index";
import { FaArrowDown } from "react-icons/fa";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import { event } from "react-ga";

const LandingPage = () => {
  const handleGoogleAnalyticsResrvierenButton = () => {
    // Tracke Button-Klick-Event
    event({
      category: 'Button',
      action: 'Click',
      label: 'Startseite Reservieren Button'
    });
  };
  const handleGoogleAnalyticsMenuButton = () => {
    // Tracke Button-Klick-Event
    event({
      category: 'Button',
      action: 'Click',
      label: 'Startseite Menu Button'
    });
  };
  const location = useLocation();
  const { selectedLanguage } = useLanguage();
  const translation = translations[selectedLanguage];

  const [currentImage, setCurrentImage] = useState(BeesRestaurant); // Zustand für das aktuelle Bild

  useEffect(() => {
    setCurrentImage(getImageForLocation()); // Bild aktualisieren, wenn sich die Sprache ändert
    // eslint-disable-next-line
  }, [selectedLanguage, location.pathname]); // Abhängigkeiten des useEffect

  const getImageForLocation = () => {
    switch (location.pathname) {
      case "/":
        return BeesRestaurant;
      case "/Reservierung":
        return selectedLanguage === "de" ? BeesReservierne : BeesReserve;
      case "/Speisekarte":
        return selectedLanguage === "de" ? BeesSpeisekartre : BeesMenu;
      case "/events":
        return  BeesEvent;
      case "/Impressum":
        return selectedLanguage === "de" ? BeesImpressum : BeesImprint;
      case "/team":
        return BeesRestaurant;
      case "/Veranstaltungen":
        return selectedLanguage === "de" ? BeesVeranstaltungen: BeesEvents;;
      default:
        return BeesRestaurant;
    }
  };

  const handleScrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className="app__bg-blue app__landingPage">
      <div className="app__landingPage_img_container">
        <img
          src={currentImage} // Verwenden Sie den Zustand für das aktuelle Bild
          alt="Biene"
          className="app__landingpage_img"
        />
      </div>
      <div className="app__landingPage_container-icon-btn">
      
      <div className={location.pathname === "/" ? "app__scroll__down__icon_startseite" : "app__scroll__down__icon"}>
                <FaArrowDown
                    color="#fef4e2"
                    onClick={handleScrollDown}
                    fontSize={30}
                />
            </div>

        {location.pathname === "/" && (
          <div className="app__landingPage_buttons">
            <a href="/Reservierung">
              <button onClick={handleGoogleAnalyticsResrvierenButton} className="app__landingPage_button">
                {translation.reservieren}
              </button>
            </a>
            <a href="/Speisekarte">
              <button onClick={handleGoogleAnalyticsMenuButton} className="app__landingPage_button">
                {translation.karte}
              </button>
            </a>
          </div>
        )}
        
      </div>
    </section>
  );

};

export default LandingPage;
