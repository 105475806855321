import Anfahrt from "./Anfahrt/anfahrt";
import Gallery from "./Gallery/gallery";
import Impressum from "./Impressum/impressum";
import InstagramSection from "./InstagramSection/instagramSection";
import Menu from "./Menu/menu";
import TimeOpend from "./Timeopend/timeopend";
import Veranstaltungen from "./Veranstaltungen/veranstaltungen";
import VeranstaltungenStartseite from "./VeranstaltungenStartseite/veranstaltungenStartseite";
import Reservierungen from "./Reservierungen/reservierungen";
import LandingPage from "./LandingPageHeader/landingPage";
import Team from "./Team/team";
import Gutscheine from "./Gutscheine/gutscheine";
import Dashboard from "./Dashboard/dashboard";
import Events from "./Dashboard/components/Events/events";
import PrivateEvents from "./Dashboard/components/prvEvents/prvevents";
import Urlaub from "./Dashboard/components/Urlaub/urlaub";
import RheinWeinWeltBadge from "./RheinWeinWeltBadge/rheinWeinWeltBadge";

export {
  Anfahrt,
  Gallery,
  Impressum,
  InstagramSection,
  Menu,
  TimeOpend,
  Veranstaltungen,
  VeranstaltungenStartseite,
  Reservierungen,
  LandingPage,
  Team,
  Gutscheine,
  Events,
  Urlaub,
  PrivateEvents,
  Dashboard,
  RheinWeinWeltBadge,
};
