import React from "react";
import "./timeOpend.css";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";

const TimeOpend = () => {
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  return (
    <section className="app__bg-creme">
      <div className="app__timeopend_schedule">
        <div className="app__timeopend_schedule-container">
          <h1 className="app__timeopend_schedule-heading">
            {translation.open}
          </h1>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">{translation.montag}</p>
            <p className="app__timeopend_schedule-time">{translation.ab17}</p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">
              {translation.dienstag}
            </p>
            <p className="app__timeopend_schedule-time">
              {translation.ruhetag}
            </p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">
              {translation.mittwoch}
            </p>
            <p className="app__timeopend_schedule-time">
              {translation.ruhetag}
            </p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">
              {translation.donnerstag}
            </p>
            <p className="app__timeopend_schedule-time">{translation.ab17}</p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">{translation.freitag}</p>
            <p className="app__timeopend_schedule-time">{translation.ab17}</p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">{translation.samstag}</p>
            <p className="app__timeopend_schedule-time">{translation.ab17}</p>
          </div>
          <div className="app__timeopend_schedule-dayTime">
            <p className="app__timeopend_schedule-day">{translation.sonntag}</p>
            <p className="app__timeopend_schedule-time">
              {translation.from11to15}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TimeOpend;
