import React from "react";
import supabase from "../../../LogIn/supabaseClient";
import { useState, useEffect } from "react";

const PreisProMenü = () => {
  const [users, setUsers] = useState([]);
  const [name, setname] = useState("");
  const [preis, setpreis] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let { data, error } = await supabase
      .from("bees_preise_pro_menü")
      .select("*")
      .order("id", { ascending: true }); // Sortiert die Daten nach ID in aufsteigender Reihenfolge
    if (data) setUsers(data);
    if (error) console.error(error);
  };

  const handleUpdatePrice = async () => {
    try {
      const { data, error } = await supabase
        .from("bees_preise_pro_menü")
        .update({ preis })
        .eq("name", name);

      if (error) {
        console.error("Fehler beim Aktualisieren:", error.message);
      } else if (data && data.length === 0) {
        console.warn(
          "Keine Datensätze wurden aktualisiert. Überprüfen Sie die Filterbedingungen."
        );
      }

      fetchUsers();
    } catch (e) {
      console.error("Ein unerwarteter Fehler ist aufgetreten:", e);
    }
  };

  return (
    <div className="events-container">
      <h2 className="events-title">Preise für die Menü's aktualisieren</h2>

      <select
        className="events-input"
        value={name}
        onChange={(e) => setname(e.target.value)}
      >
        <option value="" disabled>
          Menü auswählen
        </option>
        {users.map((user) => (
          <option key={user.id} value={user.name}>
            {user.name}
          </option>
        ))}
      </select>
      <input
        className="events-input"
        placeholder="Neuer Preis"
        value={preis}
        onChange={(e) => setpreis(e.target.value)}
      />
      <button
        className="events-button"
        onClick={(e) => {
          e.preventDefault();
          handleUpdatePrice();
        }}
      >
        Update
      </button>

      <div className="events-title"></div>
      <ul className="events-list">
        {users.map((user) => (
          <li className="events-item" key={user.id}>
            {user.name}
            {user.preis}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PreisProMenü;
