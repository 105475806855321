import supabase from "./container/LogIn/supabaseClient";
import Swal from "sweetalert2";

const checkHoliday = async (props) => {
  // Überprüfen, ob die Benachrichtigung bereits angezeigt wurde
  if (sessionStorage.getItem('holidayNotificationShown')) {
    return;
  }

  // Daten aus Supabase abrufen
  const { data, error } = await supabase.from("bees_urlaub").select("Anfang, Ende");

  if (error) {
    console.error("Fehler beim Abrufen der Urlaubsdaten:", error);
    return;
  }

  // Daten nach dem Startdatum sortieren
  data.sort((a, b) => new Date(a.Anfang) - new Date(b.Ende));

  const currentDate = new Date();
  const  selectedLanguage  = props // Sprache abrufen

  for (let holiday of data) {
    const startDate = new Date(holiday.Anfang);
    const endDate = new Date(holiday.Ende);

    // Überprüfen, ob das aktuelle Datum im Urlaubszeitraum liegt
    if (currentDate >= startDate && currentDate <= endDate) {
      // Benachrichtigung anzeigen
      Swal.fire({
        icon: 'info',
        title: '<span style="font-family: Arial, sans-serif; color: #333;">' + 
               (selectedLanguage === "en" ? "Notice" : "Hinweis") + 
               '</span>',
        html: `
          <span style="font-family: Arial, sans-serif; color: #555;">
            ${selectedLanguage === "en" ? 
              `The restaurant is closed for vacation from 
               <strong>${startDate.toLocaleDateString('en-US')}</strong> to 
               <strong>${endDate.toLocaleDateString('en-US')}</strong>.` :
              `Das Restaurant ist wegen Urlaub vom 
               <strong>${startDate.toLocaleDateString('de-DE')}</strong> bis zum 
               <strong>${endDate.toLocaleDateString('de-DE')}</strong> geschlossen.`}
          </span>
        `,
        confirmButtonText: selectedLanguage === "en" ? 'Okay' : 'Okay',
        background: '#f9f9f9',
        confirmButtonColor: '#3085d6',
        customClass: {
          content: 'customSwalContent',
        }
      });

      // Speichern Sie den Zustand, dass die Benachrichtigung angezeigt wurde
      sessionStorage.setItem('holidayNotificationShown', 'true');
      break; // Wenn ein passender Urlaub gefunden wurde, brechen Sie die Schleife ab
    }
  }
};

export default checkHoliday;
