// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__impressum-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 50px;
  font-weight: 500;
  font-size: 1.5rem;
}
.app__impressum-container div {
  padding: 5px 0px;
}
.app__impressum-container h1 {
  padding: 50px 0px;
  font-family: "SawtonCircular-Medium";
}
@media screen and (max-width: 450px) {
  .app__impressum-container {
    line-height: 30px;
    font-size: 1.2rem;
    padding: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/container/Impressum/impressum.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,oCAAoC;AACtC;AACA;EACE;IACE,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;EACf;AACF","sourcesContent":[".app__impressum-container {\r\n  text-align: center;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  line-height: 50px;\r\n  font-weight: 500;\r\n  font-size: 1.5rem;\r\n}\r\n.app__impressum-container div {\r\n  padding: 5px 0px;\r\n}\r\n.app__impressum-container h1 {\r\n  padding: 50px 0px;\r\n  font-family: \"SawtonCircular-Medium\";\r\n}\r\n@media screen and (max-width: 450px) {\r\n  .app__impressum-container {\r\n    line-height: 30px;\r\n    font-size: 1.2rem;\r\n    padding: 25px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
