import React from "react";
import "./trotzdem_offen.css";
import supabase from "../../../LogIn/supabaseClient";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { DatePicker } from 'react-rainbow-components';

const TrotzdemOffen = () => {
  const [dates, setDates] = useState([]);
  const [inputDatum, setInputDatum] = useState("");

  useEffect(() => {
    fetchDates();
  }, []);

  const fetchDates = async () => {
    let { data, error } = await supabase
      .from("bees_trotzdem_offen")
      .select("*");
    if (data) setDates(data);
    if (error) console.error(error);
  };

  const handleAddDate = async () => {
    const isoFormat = convertToISOFormat(inputDatum);
    const { error } = await supabase
      .from("bees_trotzdem_offen")
      .insert([{ Datum: isoFormat }]);
    if (error) {
      console.error(error);
      Swal.fire("Fehler", "Datum konnte nicht hinzugefügt werden", "error");
    } else {
      fetchDates();
      Swal.fire("Hinzugefügt", "Datum erfolgreich hinzugefügt", "success");
      setInputDatum(""); // Felder nach dem Hinzufügen zurücksetzen
    }
  };

  function formatDateToGerman(dateString) {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("de-DE", options);
  }

  const handleDeleteDate = async (dateId) => {
    Swal.fire({
      title: "Sind Sie sicher?",
      text: "Dieser Eintrag wird dauerhaft gelöscht!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ja, löschen!",
      cancelButtonText: "Abbrechen",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { error } = await supabase
          .from("bees_trotzdem_offen")
          .delete()
          .eq("id", dateId);
        if (error) console.error(error);
        fetchDates();
        Swal.fire("Gelöscht!", "Das Datum wurde gelöscht.", "success");
      }
    });
  };
  
  const convertToISOFormat = (germanDate) => {
    const parts = germanDate.split(".");
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`; // ISO-Format ist JJJJ-MM-TT
    }
    return ""; // Oder handle ungültige Daten wie gewünscht
  };

  const convertToValidDate = (germanDate) => {
    if (!germanDate) return null;
    const [day, month, year] = germanDate.split(".");
    return new Date(year, month - 1, day);
  };

  return (
    <div className="events-container">
      <h2 className="events-title">Öffnungszeiten hinzufügen</h2>
      <DatePicker
          id="event-date"
          formatStyle="medium"
          style={{paddingBottom:"20px"}}
          value={convertToValidDate(inputDatum)}
          placeholder="Datum der Öffnung"
          onChange={value => {
              const formattedDate = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
              setInputDatum(formattedDate);
          }}
      />

      <button className="events-button" onClick={handleAddDate}>
        Add
      </button>

      <div className="events-title"></div>
      <ul className="events-list">
        {dates.map((dateEntry) => (
          <li className="events-item-prv-Events" key={dateEntry.id}>
            {formatDateToGerman(dateEntry.Datum)}
            <div className="vertical-line-prv-Veranstaltungen"></div>
            <button
                className="delete-btn"
                onClick={() => handleDeleteDate(dateEntry.id)}
              >
                Löschen
              </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TrotzdemOffen;
