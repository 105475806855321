import React from "react";
import "./reservierungen.css";
import TimeOpend from "../Timeopend/timeopend";
import Contactform from "../ContactForm/contactForm";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";

const Reservierungen = () => {
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  return (
    <main className="app__bg-creme">
      <section className="app__reservieren_container">
        <div className="app__reservieren_container-text">
          <div className="app__reservieren_container-text_heading">
            {translation.reservierenHeading}
          </div>
          <div className="app__reservieren_container-text_beschreibung">
            {translation.reservierenText}
          </div>
        </div>
      </section>
      <TimeOpend />
      <Contactform />
    </main>
  );
};

export default Reservierungen;
