import React from "react";
import "./impressum.css";

const Impressum = () => {
  return (
    <div className="app__bg-creme app__impressum-container">
      <h1>Impressum</h1>
      <div>RheinWeinWelt KG</div>
      <div>Am Rottland 6</div>
      <div>65385 Rüdesheim am Rhein</div>
      <div>Telefon: +49 (0) 67 22 / 4191916</div>
      <div>info@beesrestaurant.de</div>
      <div>Vertretungsberechtigte Geschäftsführer: Annette Perabo</div>
      <div>Registergericht: Amtsgericht Wiesbaden</div>
      <div>Registernummer: HRA 10557</div>
      <div>Umsatzsteuer-Identifikationsnummer gemäß § 27 a</div>
      <div>Umsatzsteuergesetz:</div>
      <div>DE 337 934650</div>
      <div>Steuernummer: 37 360 30357 </div>
      <div>Angaben gemäß § 5 TMG und § 55 RStV, inhaltlich</div>
      <div>Verantwortlicher: Annette Perabo</div>
      <div>Haftungsausschluss:</div>
      <div>
          Die Inhalte unserer Webseite wurden mit größtmöglicher Sorgfalt erstellt.
      </div>
      <div>
          Wir übernehmen jedoch keine Gewähr für die Aktualität, Vollständigkeit und Richtigkeit der bereitgestellten Inhalte.
      </div>
      <div>
          Die Nutzung der Inhalte der Webseite erfolgt auf eigene Gefahr des Nutzers.
      </div>
      <div>
          Mit der reinen Nutzung unserer Webseite kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und uns zustande.
      </div>
      <div>Haftungshinweis:</div> 
      <div>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir</div>
      <div>keine Haftung für die Inhalte externer Links.</div>
      <div>Für den Inhalt der verlinkten Seiten sind ausschließlich</div>
      <div>deren Betreiber verantwortlich.</div>
      <div>Weiteres:</div>
      <a href="/Datenschutz" style={{color:"blue", textDecoration:"underline"}}>Datenschutzerklärung</a>
    </div>
  );
};

export default Impressum;
