import React, { useState, useEffect, useRef } from "react";
import supabase from "../../../LogIn/supabaseClient";
import "./tracking.css";
import { BsInstagram, BsBook, BsPeopleFill } from "react-icons/bs";
import Swal from "sweetalert2";
import {
    Card as RainbowCard,
} from 'react-rainbow-components';
import styled from 'styled-components';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const PercentageButton = styled.button`
    background-color: ${props => props.bgColor || "#ccc"};
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${props => props.hoverColor || "#aaa"};
    }
`;


const getCurrentWeekNumber = (date) => {
    // Donnerstag der aktuellen Woche
    const thursday = new Date(date);
    thursday.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 3);

    // Erster Donnerstag des Jahres
    const firstThursday = new Date(thursday.getFullYear(), 0, 4);

    // Korrektur, falls der 4. Januar nicht in der ersten Woche liegt
    if (firstThursday.getDay() !== 4) {
        firstThursday.setDate(firstThursday.getDate() - ((firstThursday.getDay() + 6) % 7) + 3);
    }

    const weekNumber = 1 + Math.round(((thursday - firstThursday) / (24 * 60 * 60 * 1000) - 3) / 7);

    return weekNumber;
};

const Tracking = () => {
   // eslint-disable-next-line
    const [data, setData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [percentageChange, setPercentageChange] = useState({ Visitor: 0, Reservierungen: 0, Instagram: 0 });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Event-Listener, um die Fensterbreite zu aktualisieren, wenn das Fenster skaliert wird
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Event-Listener entfernen, wenn die Komponente unmountet
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const chartWidth = Math.min(windowWidth * 0.9, 1400);

    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            fetchData();
            hasFetched.current = true;
        }
        // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
        let { data: allData, error: fetchError } = await supabase
            .from("bees_tracking_week")
            .select("*")
            .order("Jahr", { ascending: false })
            .order("Kw", { ascending: true });

        if (fetchError) {
            Swal.fire({
                title: 'Abruf-Fehler',
                text: 'Es gab einen Fehler beim Abrufen der Nutzeraktivitäten-Daten. Bitte informieren Sie Oracode.',
                icon: 'error',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Verstanden'
            });
            return;
        }

        const currentYear = new Date().getFullYear();
        const currentWeek = getCurrentWeekNumber(new Date());

        const currentWeekData = allData.find(item => item.Jahr === currentYear && item.Kw === currentWeek);
        const lastWeekData = allData.find(item => item.Jahr === currentYear && item.Kw === currentWeek - 1);
        //Prozentberechnung
        if (lastWeekData && currentWeekData) {
            const changeVisitor = lastWeekData.Visitor !== 0 
                ? ((currentWeekData.Visitor - lastWeekData.Visitor) / lastWeekData.Visitor) * 100 
                : currentWeekData.Visitor > 0 
                    ? 100 
                    : 0;
        
            const changeReservierungen = lastWeekData.Reservierungen !== 0 
                ? ((currentWeekData.Reservierungen - lastWeekData.Reservierungen) / lastWeekData.Reservierungen) * 100 
                : currentWeekData.Reservierungen > 0 
                    ? 100 
                    : 0;
        
            const changeInstagram = lastWeekData.Instagram !== 0 
                ? ((currentWeekData.Instagram - lastWeekData.Instagram) / lastWeekData.Instagram) * 100 
                : currentWeekData.Instagram > 0 
                    ? 100 
                    : 0;
        
            setPercentageChange({ Visitor: changeVisitor, Reservierungen: changeReservierungen, Instagram: changeInstagram });
        }
        
        

        if (!currentWeekData) {
            const { error: insertError } = await supabase
                .from("bees_tracking_week")
                .insert([{ Jahr: currentYear, Kw: currentWeek, Visitor: 0, Reservierungen: 0, Instagram: 0 }]);

            if (insertError) {
                Swal.fire({
                    title: 'Einfüge-Fehler',
                    text: 'Es gab einen Fehler beim Einfügen der Daten für die aktuelle Woche. Bitte informieren Sie Oracode.',
                    icon: 'error',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Verstanden'
                });
                return;
            }

            allData.push({ Jahr: currentYear, Kw: currentWeek, Visitor: 0, Reservierungen: 0, Instagram: 0 });
        }

        setData(allData);
        aggregateMonthlyData(allData);
    };

    const aggregateMonthlyData = (data) => {
        const months = [
            'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
            'August', 'September', 'Oktober', 'November', 'Dezember'
        ];

        const aggregatedData = months.map(month => ({
            month,
            Visitor: 0,
            Reservierungen: 0,
            Instagram: 0
        }));

        data.forEach(week => {
            const date = new Date(week.Jahr, 0, 1);
            date.setDate(7 * week.Kw);
            const monthIndex = date.getMonth();
            aggregatedData[monthIndex].Visitor += week.Visitor;
            aggregatedData[monthIndex].Reservierungen += week.Reservierungen;
            aggregatedData[monthIndex].Instagram += week.Instagram;
        });

        setMonthlyData(aggregatedData);
    };

    return (
        <div className="Traking">
            <div className="TrakingInfo" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '20px',fontWeight:"550" }}>
            <RainbowCard 
                title="Besucher" 
                actions={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsPeopleFill size={30} />
                        <span 
                            style={{ 
                                backgroundColor: '#E8F5E9', 
                                border: 'none', 
                                borderRadius: '5px', 
                                padding: '5px 10px', 
                                marginLeft: '10px', 
                                cursor: 'pointer'
                            }}
                            onClick={() => Swal.fire({
                                title: 'Besucher Änderung',
                                text: `Die prozentuale Veränderungen der Besucher im Vergleich zur letzten Woche beträgt ${percentageChange.Visitor.toFixed(2)}%.`,
                                icon: 'info',
                                confirmButtonColor: '#4CAF50',
                                confirmButtonText: 'Verstanden'
                            })}
                        >
                            <PercentageButton bgColor="#C8EFD4" hoverColor="#AED9C0">
                            {percentageChange.Visitor.toFixed(2)}%
                            </PercentageButton>
                        </span>
                    </div>
                }
                style={{ backgroundColor: '#E8F5E9', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '1400px' }}
            >
                                <LineChart width={chartWidth} height={150} data={monthlyData}>
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="Visitor" stroke="#4CAF50" />
                    </LineChart>
                </RainbowCard>
                <RainbowCard 
                    title="Reservierungen" 
                    actions={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BsBook size={30} />
                            <span 
                                style={{ 
                                    backgroundColor: '#FFF3E0', 
                                    border: 'none', 
                                    borderRadius: '5px', 
                                    padding: '5px 10px', 
                                    marginLeft: '10px', 
                                    cursor: 'pointer'
                                }}
                                onClick={() => Swal.fire({
                                    title: 'Reservierungen Änderung',
                                    text: `Die prozentuale Veränderungen der Reservierungen im Vergleich zur letzten Woche beträgt ${percentageChange.Reservierungen.toFixed(2)}%.`,
                                    icon: 'info',
                                    confirmButtonColor: '#FFC107',
                                    confirmButtonText: 'Verstanden'
                                })}
                            >
                                <PercentageButton bgColor="#FFEBCC" hoverColor="#FFD8A8">
                                {percentageChange.Reservierungen.toFixed(2)}%
                                </PercentageButton>
                            </span>
                        </div>
                    }
                    style={{ backgroundColor: '#FFF3E0', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '1400px' }}
                >
                    <LineChart width={chartWidth} height={150} data={monthlyData}>
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="Reservierungen" stroke="#FFC107" />
                    </LineChart>
                </RainbowCard>
                <RainbowCard 
                    title="Instagram" 
                    actions={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BsInstagram size={30} />
                            <span 
                                style={{ 
                                    backgroundColor: '#FCE4EC', 
                                    border: 'none', 
                                    borderRadius: '5px', 
                                    padding: '5px 10px', 
                                    marginLeft: '10px', 
                                    cursor: 'pointer'
                                }}
                                onClick={() => Swal.fire({
                                    title: 'Instagram Änderung',
                                    text: `Die prozentuale Veränderungen der Instagram-Interaktionen im Vergleich zur letzten Woche beträgt ${percentageChange.Instagram.toFixed(2)}%.`,
                                    icon: 'info',
                                    confirmButtonColor: '#E91E63',
                                    confirmButtonText: 'Verstanden'
                                })}
                            >
                              <PercentageButton bgColor="#FAD2E1" hoverColor="#F8A1C4">    
                                {percentageChange.Instagram.toFixed(2)}%
                                </PercentageButton>
                            </span>
                        </div>
                    }
                    style={{ backgroundColor: '#FCE4EC', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',width: '1400px' }}
                >
                    <LineChart width={chartWidth} height={150} data={monthlyData}>
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="Instagram" stroke="#E91E63" />
                    </LineChart>
                </RainbowCard>
            </div>
        </div>
    );
};

export default Tracking;