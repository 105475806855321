import BeesEvent from "./Bees_Events.png";
import BeesImpressum from "./Bees_Impressum.png";
import BeesReservierne from "./Bees_Reservieren.png";
import BeesSpeisekartre from "./Bees_Speisekarte.png";
import BeesRestaurant from "./Bees_Startseite.png";
import BeesVeranstaltungen from "./Bees_Veranstaltungen.png";
import DogFriendly from "./DogFriendly.png"
import RheinWeinWeltLogo from "./RheinWeinWelt.png"
import BeesReserve from "./Bees_Reserve_EN.png"
import BeesMenu from "./Bees_Menu_EN.png"
import BeesImprint from "./Bees_Imprint_EN.png"
import BeesEvents from "./Bees_Events_EN.png"

export {
  BeesEvent,
  BeesImpressum,
  BeesReservierne,
  BeesRestaurant,
  BeesSpeisekartre,
  BeesVeranstaltungen,
  DogFriendly,
  RheinWeinWeltLogo,
  BeesReserve,
  BeesMenu,
  BeesImprint,
  BeesEvents,
};
