// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import Events from "./components/Events/events";
import supabase from "../LogIn/supabaseClient";
import PrivateEvents from "./components/prvEvents/prvevents";
import Urlaub from "./components/Urlaub/urlaub";
import PreisProMenü from "./components/PreisProMenü/preisProMenü";
import ChangeMenu from "./components/Changemenu/changeMenu";
import { useAuth } from "../logInProvider/logInProvider";
import { Navigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";
import Tracking from "./components/Tracking/tracking";
import TrotzdemOffen from "./components/trotzdem_offen/trotzdem_offen";


export default function Dashboard() {
  const { setIsLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Fehler beim Ausloggen:", error);
    } else {
      setIsLoggedIn(false);
      <Navigate to="/logIn" />;
    }
  };

  return (
    <div className="app__bg-blue app__dashboard_container">
      <div className="app__dashboard-title">Dashboard</div>
      <Tracking />
      <IoArrowBack // <-- Das Zurück-Pfeil-Icon
        onClick={() => navigate("/")}
        className="app__logIn_back-icon"
        size={50}
        style={{ position: "absolute", top: "10px", left: "10px" }} // Positionieren Sie das Icon in der oberen linken Ecke
      />
      <Events />
      <PrivateEvents />
      <TrotzdemOffen />
      <Urlaub />
      <PreisProMenü />
      <ChangeMenu />
      <button onClick={handleLogout} className="logout-button">
        Log Out
      </button>
    </div>
  );
};
