import {
  Anfahrt,
  Gallery,
} from "../../container/index.js";
import { useEffect } from "react";
import checkHoliday from "../../urlaubBenachrichtigung.js";
import { useLanguage } from "../../language/currentLanguage.js";
import supabase from "../../container/LogIn/supabaseClient.js";
import { lazy } from "react";

const InstagramSection = lazy(() => import('../../container/InstagramSection/instagramSection.jsx'));
const TimeOpend = lazy(() => import('../../container/Timeopend/timeopend.jsx'));
const VeranstaltungenStartseite = lazy(() => import('../../container/VeranstaltungenStartseite/veranstaltungenStartseite.jsx'));

const Startseite = () => {
  const { selectedLanguage } = useLanguage();

  const incrementVisitorCount = async () => {
    const currentYear = new Date().getFullYear();
    const currentWeek = getCurrentWeekNumber();

    // Fetch data for the current year and week
    const { data: allData, error: fetchError } = await supabase
        .from("bees_tracking_week")
        .select("*")
        .eq('Jahr', currentYear)
        .eq('Kw', currentWeek);

    if (fetchError) {
        console.error("Error fetching the data:", fetchError);
        return;
    }

    const currentWeekData = allData.find(item => item.Jahr === currentYear && item.Kw === currentWeek);

    if (!currentWeekData) {
        // If no data for current week, insert a new record with Visitor count of 1
        const { error: insertError } = await supabase
            .from("bees_tracking_week")
            .insert([{ Jahr: currentYear, Kw: currentWeek, Visitor: 1, Reservierungen: 0, Instagram: 0 }]);

        if (insertError) {
            console.error("Error inserting new record:", insertError);
            return;
        }

    } else {
        // If data exists for current week, increment the Visitor count
        const newVisitorCount = currentWeekData.Visitor + 1;

        const { error: updateError } = await supabase
            .from("bees_tracking_week")
            .update({ Visitor: newVisitorCount })
            .eq('Jahr', currentYear)
            .eq('Kw', currentWeek);

        if (updateError) {
            console.error("Error updating the Visitor count:", updateError);
            return;
        }
    }
};


  

    function getCurrentWeekNumber() {
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 1);
        const startOfWeek = new Date(
          startOfYear.setDate(startOfYear.getDate() - startOfYear.getDay())
        );

        const diffInTime = now.getTime() - startOfWeek.getTime();
        const diffInWeeks = Math.floor(diffInTime / (1000 * 3600 * 24 * 7));

        return diffInWeeks + 1; // Add 1 to account for the first week
    }

    useEffect(() => {
      checkHoliday(selectedLanguage);
      // Überprüfen, ob der Besucherzähler für diese Session bereits erhöht wurde
      if (!sessionStorage.getItem("visitorCounted")) {
          incrementVisitorCount();
          sessionStorage.setItem("visitorCounted", "true");
      }
        // eslint-disable-next-line
    }, []);

  return (
    <main>
      <Gallery />
      <TimeOpend />
      <VeranstaltungenStartseite />
      <InstagramSection />
      <Anfahrt />
    </main>
  );
};

export default Startseite;
