import React, { useState, useEffect } from "react";
import supabase from "../LogIn/supabaseClient";
import "./veranstaltungenStartseite.css";
import { useLanguage } from "../../language/currentLanguage";
import translations from "../../language/translation";
import { event } from "react-ga";

const VeranstaltungenStartseite = () => {
  const [NextEvent, setNextEvent] = useState({});
  const { selectedLanguage } = useLanguage();
  const translation = translations[selectedLanguage];

  const handleGoogleAnalyticsNextEventLink = () => {
    // Tracke Link-Klick-Event
    event({
      category: 'Link',
      action: 'Click',
      label: 'Veranstaltung Startseite Next Event Link'
    });
  };

  
  const handleGoogleAnalyticsPrivateEventRequestRedirect = () => {
    // Tracke Link-Klick-Event
    event({
      category: 'Link',
      action: 'Click',
      label: 'Veranstaltung Startseite Private Veranstaltung Link',
    });
  };

  const nextEvent = {
      title: "Name",
      date: "Datum",
      descriptionDE: "Info",
      descriptionENG: "Info_Eng",
      closed: "Geschlossen",
  };

  useEffect(() => {
    const fetchNextEvent = async () => {
      let { data, error } = await supabase
        .from("bees_events")
        .select("*")
        .order("Datum", { ascending: true })
        .limit(1);
      if (data && data.length > 0) setNextEvent(data[0]);
      if (error) console.error(error);
    };

    fetchNextEvent();
  }, []);

  if (!translation) {
    return <div>Loading...</div>;
  }

  const descriptionField = selectedLanguage === "de" ? NextEvent[nextEvent.descriptionDE] : NextEvent[nextEvent.descriptionENG];
  const shortDescription = descriptionField && typeof descriptionField === "string" ? descriptionField.split(" ").slice(0, 8).join(" ") + "..." : "";

  const dateObject = new Date(NextEvent.Datum);
  const formattedDate = `${dateObject.getDate().toString().padStart(2, '0')}.${(dateObject.getMonth() + 1).toString().padStart(2, '0')}.${dateObject.getFullYear()}`;

  return (
    <section className="app__bg-creme">
      <h1 style={{display:"flex",justifyContent:"center"}} className="app__timeopend_schedule-heading">{selectedLanguage === "de"? "Veranstaltungen" : "Events" }</h1>
      <div className="app__veranstaltungenStartseite">
        {NextEvent.Name && (
          <div className="app__veranstaltungenStartseite-Event">
            <a onClick={handleGoogleAnalyticsNextEventLink} href="/veranstaltungen">
              <div className="app__veranstaltungenStartseite_Event-Überschrift">
                <p>{translation.nextEvent}</p>
              </div>
              <div className="app__veranstaltungenStartseite_Event-Details">
                <div>{NextEvent.Name}</div>
                <div>{`${selectedLanguage === "de" ? "am" : "on"} ${formattedDate} ${selectedLanguage === "de" ? "um" : "at"} ${NextEvent.Uhrzeit}`}</div>
                <div>{shortDescription}</div>
              </div>
            </a>
          </div>
        )}
        <div className="app__veranstaltungenStartseite-Your-Event-section">
          <div className="app__veranstaltungenStartseite_Event-Überschrift-Your-Event-header">
            <p>{translation.yourEvent}</p>
          </div>
          <div className="app__veranstaltungenStartseite-Your-Event-content">
            <div className="app__veranstaltungenStartseite-Your-Event-text">
              {translation.yourEventText}
              <a onClick={handleGoogleAnalyticsPrivateEventRequestRedirect} href="/veranstaltungen#form">
              <button className="app__veranstaltungenStartseite-Book-Event-button">
                {translation.jetztAnfragen}
              </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VeranstaltungenStartseite;
