import BeesFood from "./Bees FOOD.jpg";
import BeesNaomi from "./Bees NAOMI.jpg";
import BeesPralinen from "./Bees PRALINEN.jpg";
import BeesRene from "./Bees RENE.jpg";
import BeesRestaurant from "./Bees RESTAURANT.jpg";
import BeesRestaurant2 from "./Bees Restaurant 2.jpg";

export {
  BeesFood,
  BeesNaomi,
  BeesPralinen,
  BeesRene,
  BeesRestaurant,
  BeesRestaurant2,
};
