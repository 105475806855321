import React from "react";
import "./anfahrt.css";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";

const Anfahrt = () => {
  const { selectedLanguage } = useLanguage(); // Anpassung hier: Destructuring des Hooks
  const translation = translations[selectedLanguage];
  return (
    <section className="app__bg-creme">
      <div className="app__anfahrt_container">
        <div className="app__anfahrt_container_text">
          <div className="app__anfahrt_container_heading">
            {translation.anfahrtHeading}
          </div>
          <div className="app__anfahrt_container_description">
            {translation.anfahrtText}
          </div>
        </div>
        <iframe
          className="app__anfahrt_container_googlmaps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2565.812708945396!2d7.913254977550813!3d49.977370821312434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bdef3312f7266d%3A0xb5a04de4a876867d!2sBEES%20Restaurant!5e0!3m2!1sde!2sde!4v1691696097686!5m2!1sde!2sde"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="GoogleMapsKartetoBeesRestaurant"
        ></iframe>
      </div>
    </section>
  );
};

export default Anfahrt;
