import React, { lazy, Suspense, useState, useEffect, memo } from 'react';
import Startseite from "./components/Startseite/startseite";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import {
  LandingPage,
  Menu,
  Reservierungen,
  // eslint-disable-next-line
  // Team,
} from "./container";
import { Navbar, Footer } from "./components";
import { useAuth } from "./container/logInProvider/logInProvider";
import supabase from "./container/LogIn/supabaseClient";
import Datenschutz from './container/Datenschutz/datenschutz';

const LogIn = lazy(() => import('./container/LogIn/logIn'));
const Dashboard = lazy(() => import('./container/Dashboard/dashboard'));
const Impressum = lazy(() => import('./container/Impressum/impressum'));
const Gutscheine = lazy(() => import('./container/Gutscheine/gutscheine'));
const Veranstaltungen = lazy(() => import('./container/Veranstaltungen/veranstaltungen'));


const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Laden...</div>}>
        <AppContent />
      </Suspense>
    </BrowserRouter>
  );
};

const AppContent = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === '/Brunchkarte/') {
      navigate('/Speisekarte');  // Umleitung
    }
  }, [location, navigate]); 

  useEffect(() => {
    if (location.pathname === '/Brunchkarte') {
      navigate('/Speisekarte');  // Umleitung
    }
  }, [location, navigate]); 

  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const isLoginOrDashboard = ["/logIn", "/dashboard"].includes(
    location.pathname
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
    // ...
  }, [setIsLoggedIn]);

  const LoadingComponent = () => {
    return <div>Laden...</div>;
  };
  return (
    <>
      {!isLoginOrDashboard && <Navbar />}
      {!isLoginOrDashboard && <LandingPage />}
      <Routes>
        <Route path="/" element={<Startseite />} />
        <Route path="/logIn" element={<LogIn />} />
        <Route
          path="/dashboard"
          element={
            loading ? (
              <LoadingComponent />
            ) : isLoggedIn ? (
              <Dashboard />
            ) : (
              <Navigate to="/logIn" /> 
            )
          }
        />

        <Route path="/Speisekarte" element={<Menu />} />
        <Route path="/Reservierung" element={<Reservierungen />} />
        <Route path="/Veranstaltungen" element={<Veranstaltungen />} />
        <Route path="/Impressum" element={<Impressum />} />
        <Route path="/Gutscheine" element={<Gutscheine />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
        {/*<Route path="/team" element={<Team />} />*/}
      </Routes>
      {!isLoginOrDashboard && <Footer />}
    </>
  );
};

export default memo(App);