// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__reservieren_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app__reservieren_container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 500px;
}
.app__reservieren_container-text_heading {
  font-size: 3rem;
  color: #000;
  font-weight: 600;
  padding: 20px 0;
  padding-top: 50px;
}
.app__reservieren_container-text_beschreibung {
  font-size: 1.25rem;
  line-height: 30px;
  padding-bottom: 50px;
}
@media screen and (max-width: 1200px) {
  .app__reservieren_container-text_heading {
    font-size: 1.99rem;
  }
}
@media screen and (max-width: 600px) {
  .app__reservieren_container-text_heading {
    font-size: 1.75rem;
  }
  .app__reservieren_container-text {
    max-width: 300px;
  }
}
@media screen and (max-width: 375px) {
  .app__reservieren_container-text_heading {
    font-size: 1.49rem;
  }
}
@media screen and (max-width: 320px) {
  .app__reservieren_container-text_heading {
    font-size: 1.3rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/container/Reservierungen/reservierungen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;EACA;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".app__reservieren_container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.app__reservieren_container-text {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-align: center;\r\n  max-width: 500px;\r\n}\r\n.app__reservieren_container-text_heading {\r\n  font-size: 3rem;\r\n  color: #000;\r\n  font-weight: 600;\r\n  padding: 20px 0;\r\n  padding-top: 50px;\r\n}\r\n.app__reservieren_container-text_beschreibung {\r\n  font-size: 1.25rem;\r\n  line-height: 30px;\r\n  padding-bottom: 50px;\r\n}\r\n@media screen and (max-width: 1200px) {\r\n  .app__reservieren_container-text_heading {\r\n    font-size: 1.99rem;\r\n  }\r\n}\r\n@media screen and (max-width: 600px) {\r\n  .app__reservieren_container-text_heading {\r\n    font-size: 1.75rem;\r\n  }\r\n  .app__reservieren_container-text {\r\n    max-width: 300px;\r\n  }\r\n}\r\n@media screen and (max-width: 375px) {\r\n  .app__reservieren_container-text_heading {\r\n    font-size: 1.49rem;\r\n  }\r\n}\r\n@media screen and (max-width: 320px) {\r\n  .app__reservieren_container-text_heading {\r\n    font-size: 1.3rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
