import { useEffect, useState } from "react";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import "./menu.css";
import supabase from "../LogIn/supabaseClient";

const Menu = () => {
  const [data, setData] = useState([]);
  const [preisProMenu, setpreisProMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("Menü");
  const { selectedLanguage } = useLanguage();
  const translation = translations[selectedLanguage];
  let showMenu = "bees_speisekarte_de"

    if(selectedLanguage === "en") {
    showMenu = "bees_speisekarte_en"
    }

  useEffect(() => {
    async function fetchData() {
      try {
        const { data, error } = await supabase
          .from(showMenu)
          .select();
        if (error) throw error;
        setData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line
  }, [selectedLanguage]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data, error } = await supabase
          .from("bees_preise_pro_menü")
          .select()
          .order("id", { ascending: true });
        if (error) throw error;
        setpreisProMenu(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const groupDataByCategory = (data) => {
    const groupedData = {};

    data.forEach((item) => {
      const category = item.Kategorie || "undefined";
      if (!groupedData[category]) {
        groupedData[category] = [];
      }
      groupedData[category].push(item);
    });

    return groupedData;
  };

  const filteredData = data.filter((item) => item.Karte === selectedCategory);
  const groupedData = groupDataByCategory(filteredData);

  return (
    <section className="app__bg-creme">
      <div className="app__menu_container">
        {loading ? (
          <div>Laden...</div>
        ) : error ? (
          <div>Fehler beim Laden der Daten: {error}</div>
        ) : (
          <>
            <div className="app__menu_container-text">
              <div className="app__menu_container-text_heading">
                {translation.menuHeading}
              </div>
              <div className="app__menu_container-text_beschreibung">
                {translation.menuText}
              </div>
            </div>
            <div className="app__menu_container_buttons">
              <button
                className={`app__menu_menu-button ${
                  selectedCategory === "Menü" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Menü")}
              >
                {translation.menü}
              </button>
              <button
                className={`app__menu_menu-button ${
                  selectedCategory === "A_La_Cart" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("A_La_Cart")}
              >
                À La Carte
              </button>
              <button
                className={`app__menu_menu-button ${
                  selectedCategory === "Brunch" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Brunch")}
              >
                Brunch
              </button>
            </div>
            <div className="app__menu_category app__menu-heading">
              {selectedCategory.replace(/_/g, " ")}
            </div>
            <div className="app__menu_items_container">
              {Object.entries(groupedData).map(([category, items]) => (
                <div key={category} className={`app__menu_category-container`}>
                  {category !== "undefined" && (
                    <>
                      <p className="app__menu_category">{category}</p>
                    </>
                  )}
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className={`app__menu_menu-item-${item.Karte}`}
                    >
                      <p className="app__menu_menu-name">{item.Name}</p>
                      <p className="app__menu_menu-beschreibung">
                        {item.Beschreibung}
                      </p>
                      <p className="app__menu_menu-preis">
                        {item.Preis ? `${item.Preis}` : null}
                      </p>
                      <p className="app__menu_menu-extra">{item.Extra}</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="app__menu_menu-preise">
              {selectedCategory === "Menü" && preisProMenu.length > 0 && (
                <div>
                  <div>{`${preisProMenu[0].name} ${preisProMenu[0].preis}`}</div>
                </div>
              )}
            </div>
            <div className="app__menu_menu-preise">
              {selectedCategory === "Menü" && preisProMenu.length > 1 && (
                <div>{`${preisProMenu[1].name} ${preisProMenu[1].preis}`}</div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Menu;
