import React, { useState, useEffect } from "react";
import supabase from "../LogIn/supabaseClient";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Input, DatePicker, CounterInput } from "react-rainbow-components";
import "./veranstaltungen.css";
import translations from "../../language/translation";
import { useLanguage } from "../../language/currentLanguage";
import { useLocation } from "react-router-dom";
import { event } from "react-ga";


const SERVICE_ID = "service_rn4ywvd";
const TEMPLATE_ID = "template_revtfge";//hier muss noch die richtige Template-Id hinzugeefügt werden
const USER_ID = "SqPLX_yZBQRndzUy3";

const Veranstaltungen = () => {
  const [tableData, setTableData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("bees_events");
  const [selectedButton, setSelectedButton] = useState("Bees Events");
  const { selectedLanguage } = useLanguage();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 450);
  const [formData, setFormData] = useState({
    name: "",
    nachname: "",
    anzahlPersonen: "",
    email:"",
    telefon:"",
    datum: new Date(),
    phone:"",
  });
  const [showForm, setShowForm] = useState(false);
  const translation = translations[selectedLanguage];

  const handleGoogleAnalyticsVeranstaltungenSubmits = () => {
    // Tracke Button-Klick-Event
    event({
      category: 'Button',
      action: 'Click',
      label: 'Submit Button Private Veranstaltungen', 
    });
  };

  const location = useLocation();
  useEffect(() => {
    // Überprüfen Sie, ob der Hash "#form" ist
    if (location.hash === "#form") {
      handleTableChange("bees_prv_veranstaltung", "Private Veranstaltungen");
    }
  }, [location.hash]);

  // Event-Listener, um Änderungen der Fenstergröße zu verfolgen
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 450);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tableConfigs = {
    "bees_events": {
      title: "Name",
      date: "Datum",
      descriptionDE: "Info",
      descriptionENG: "Info_Eng",
      closed: "Geschlossen",
    },
    "bees_prv_veranstaltung": {
      title: "Prv_Name",
      date: "Datum",
      closed: "Geschlossen",
    }
  };

  const fetchData = async (tableName) => {
    const { data, error } = await supabase.from(tableName).select("*");
    if (data) {
      setTableData(data);
    }
    if (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(selectedTable);
  }, [selectedTable]);

  const handleTableChange = (newTable, newButton) => {
    setSelectedTable(newTable);
    setSelectedButton(newButton);
    setShowForm(newTable === "bees_prv_veranstaltung");
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: `${selectedLanguage === "de" ? "Nachricht erfolgreich versendet." : "Message successfully sent."}`,
      });
      
    },
    (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: `${selectedLanguage === "de" ? "Uups, etwas ist schief gelaufen." : "Ooops, something went wrong"}`,
          text: error.text,
      });
      
    }
    );
  };

  const config = tableConfigs[selectedTable];
  const descriptionField = selectedLanguage === "de" ? config.descriptionDE : config.descriptionENG;
  const sortedTableData = tableData.sort((a, b) => new Date(a[config.date]) - new Date(b[config.date]));
  const noEventsMessage = sortedTableData.length === 0 && !showForm;
  return (
    <main className="app__bg-creme">
      <div className="app__veranstaltungen_main-container">
        <div className="app__veranstaltungen_container">
          <h1 className="app__veranstaltungen_title">
            {selectedButton}
          </h1>
          {
            isMobile ? (
              <select className="app__veranstaltungen_dropdown" onChange={(e) => handleTableChange(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                <option value="bees_events">Bees Events</option>
                <option value="bees_prv_veranstaltung">{translation.prvVeranstaltung}</option>
              </select>
            ) : (
              <div className="app__veranstaltungen_button-container">
                <button 
                    className={`app__veranstaltungen_button ${selectedButton === "Bees Events" ? "active" : ""}`} 
                    onClick={() => handleTableChange("bees_events","Bees Events")}
                >
                    Bees Events
                </button>
                <button 
                    className={`app__veranstaltungen_button ${selectedButton === "Private Events" || selectedButton === "Private Veranstaltungen" ? "active" : ""}`} 
                    onClick={() => handleTableChange("bees_prv_veranstaltung", `${selectedLanguage === "de" ? "Private Veranstaltungen" : "Private Events"}`)}
                >
                    {translation.prvVeranstaltung}
                </button>
              </div>
            )
          }
          {showForm ? (
            <div className="app__veranstaltungen_item">
              <div className="veranstaltung-intro-container">
                  <h2 className="veranstaltung-intro-title">{translation.prvVeranstaltungHeading}</h2>
                  <p className="veranstaltung-intro-text">
                      {translation.prvVeranstaltungText}
                  </p>
              </div>
              <form onSubmit={handleSubmit}>
              <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>{translation.vorname}</div>
              <Input
                id="form_firstname"
                name="user_firstname"
                value={formData.name}
                required
                onChange={e => handleInputChange("name", e.target.value)}
                placeholder={`${translation.vorname}...`}
                className="app__veranstaltungen_content"
              />
              <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>{translation.nachname}</div>
              <Input
                id="form_lastname"
                name="user_lastname"
                value={formData.nachname}
                required
                onChange={e => handleInputChange("nachname", e.target.value)}
                placeholder={`${translation.nachname}...`}
                className="app__veranstaltungen_content"
              />
               <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>E-Mail</div>
              <Input
                id="form_email"
                name="user_email"
                value={formData.email}
                required
                onChange={e => handleInputChange("email", e.target.value)}
                placeholder="E-Mail…"
                className="app__veranstaltungen_content"
              />
               <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>{translation.telefon}</div>
              <Input
                id="form_phoneNumber"
                name="user_number"
                placeholder={`${translation.telefon}...`}
                value={formData.phone}
                required
                onChange={e => handleInputChange("phone", e.target.value)}
                className="app__veranstaltungen_content"
              />
              <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>{translation.amountOfPerson}</div>
              <CounterInput
                id="form_amountOfPerson"
                name="user_amountOfPeople"
                value={formData.anzahlPersonen}
                required
                onChange={value => handleInputChange("anzahlPersonen", value)}
                className="app__veranstaltungen_content"
                min={1}
                placeholder={translation.amountOfPerson}
                size="medium"
              />
              <div className="labelInput" style={{color:"var(--color-white)", fontFamily:"SawtonCircular-Medium"}}>{translation.datum}</div>
              <DatePicker
                id="form_date"
                name="user_date"
                value={formData.datum}
                required
                onChange={value => handleInputChange("datum", value)}
                className="app__veranstaltungen_content"
              />
             <div style={{ display: "flex", justifyContent: "center" }}>
              <button onclick={handleGoogleAnalyticsVeranstaltungenSubmits} className="app__veranstaltungen_button" type="submit"style={{ marginTop:"15px",padding:"8px 20px",border:"2px solid var(--color-marone)"}}>
                {translation.bestätigen}
              </button>
            </div>
            </form>
            </div>
          ) : (
            <>
              {noEventsMessage && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontSize: '1.2em',
                  color: '#333',
                  padding: '20px',
                  textAlign: 'center'
              }}>
                  {selectedLanguage === "de" 
                      ? "Derzeit haben wir keine geplanten Veranstaltungen. Schauen Sie bald wieder vorbei und bleiben Sie auf dem Laufenden."
                      : "Currently, we have no scheduled events. Check back shortly and stay updated."
                  }
              </div>
              
              )}
              <ul className="app__veranstaltungen_list">
                {sortedTableData.map((row, index) => {
                  const dateObject = new Date(row[config.date]);
                  const formattedDate = `${dateObject.getDate().toString().padStart(2, '0')}.${(dateObject.getMonth() + 1).toString().padStart(2, '0')}.${dateObject.getFullYear()}`;

                  return (
                    <li className="app__veranstaltungen_item" key={index}>
                      <div className="app__veranstaltungen_content">
                        {row[config.title] && <h2>{row[config.title]}<p>{formattedDate}</p></h2>}
                        {row[descriptionField] && <p>{row[descriptionField]}</p>}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </main>
  );
};

export default Veranstaltungen;
